import { Component, ElementRef, EventEmitter, Output } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ContactDialogComponent } from '../contact-dialog/contact-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { SearchBoxComponent } from '../search-box/search-box.component';
import { UserAvatarButtonComponent } from '../../user/user-avatar-button/user-avatar-button.component';
import { UserShoppingBagButtonComponent } from '../../shopping/user-shopping-bag-button/user-shopping-bag-button.component';
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    MatToolbarModule,
    MatButtonModule,
    RouterModule,
    MatIconModule,
    SearchBoxComponent,
    UserAvatarButtonComponent,
    UserShoppingBagButtonComponent,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  @Output() onMenuToggle = new EventEmitter<boolean>();
  @Output() onCartToggle = new EventEmitter<boolean>();
  constructor(private dialog: MatDialog) {}
  openContactDialog() {
    this.dialog.open(ContactDialogComponent, {
      width: '200px',
      autoFocus: false,
    });
  }
}
