@if (data?.product) {
@if (!noTitle) {
<div class="no">
  <h5>{{ data.product.no }}</h5>
</div>
<div class="name">
  <img ngSrc="{{ data.product.images[0] | fileurl }}" width="64" height="64" />
  <h4>{{ data.product.name }}</h4>
</div>
}
<div class="divider"></div>
<a class="attr-values" [routerLink]="offline?null:['/shopping/product',data.product.no]" [queryParams]="{attrs:queryAttrs}"
  aria-label="go to product page">
  <button mat-icon-button class="close" (click)="$event.stopPropagation();$event.preventDefault(); onRemove.emit(data)">
    <mat-icon >close</mat-icon>
  </button>
  
  @for (value of data.attr_values; track $index) {
  <div class="attr-value">
    <span class="name">{{ value.attr.name }}:</span>
    @if (value.image) {
    <img ngSrc="{{ value.image | fileurl }}" width="36" height="36" />
    }
    <span class="value">{{ value.name |titlecase}}</span>
  </div>
  <div class="divider"></div>
  }
  <div class="attr-value">
    <span class="name">{{ data.priced_attr_value.attr.name }}:</span>
    @if (data.priced_attr_value.image) {
    <img ngSrc="{{ data.priced_attr_value.image | fileurl }}" width="36" height="36" />
    }
    <span class="value">{{ data.priced_attr_value.name |titlecase}}</span>
    <span class="price">
      $ <span class="amount">{{ data.price || data.priced_attr_value.price|number:'.1' }} </span>
      @if (data.price>0&&data.price<data.priced_attr_value.price) { <span class="origin">(${{ data.priced_attr_value.price|number:'.1' }})</span>
    }
    </span>
  </div>
</a>
<!-- <div class="divider"></div> -->
<div class="summary">
  <nz-input-group nzAddOnBefore="Quantity" >
    <nz-input-number [(ngModel)]="quantity" (ngModelChange)="onQuantityChanged()" [nzMin]="1"
      [nzStep]="1" [nzDisabled]="offline"></nz-input-number>
  </nz-input-group>
  <div class="offline" matTooltip="This item is no longer available.">Offline</div>
  <div class="flex-spacer"></div>
  <span class="price">
    $ <span class="amount">{{ (data.price || data.priced_attr_value.price)* data.quantity |number:'.1'}} </span>
  </span>
</div>
}
<ng-content></ng-content>
