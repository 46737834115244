<div class="page-content">
  <h1>Shipping Addresses</h1>

  <div class="content">
    @if (loading) {
    <div class="loading">
      <mat-spinner [diameter]="48"></mat-spinner>
    </div>
    }@else{
    <div class="items" cdkDropList (cdkDropListDropped)="drop($event)">
      @for (item of items; track $index) {
      <app-user-address-item
        [data]="item"
        [@showup]="{
          value: ':enter',
          params: { duration: 300, delay: 100 + $index * 150 }
        }"
        cdkDrag
      >
        <button mat-icon-button (click)="updateAddress(item, $index)">
          <mat-icon>edit</mat-icon>
        </button>
      </app-user-address-item>
      }
      <button
        class="new"
        matRipple
        (click)="newAddress()"
        [@showup]="{
          value: ':enter',
          params: { duration: 300, delay: 100 + items.length * 150 }
        }"
      >
        <mat-icon inline>add_circle</mat-icon>
        <span>Add new address</span>
      </button>
    </div>
    <ul
      [@showup]="{
        value: ':enter',
        params: { duration: 300, delay: 250 + items.length * 150 }
      }"
    >
      <li>The first address is the default one.</li>
      <li>Drag to reorder addresses.</li>
    </ul>
    }
  </div>
</div>
