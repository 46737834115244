<button
  mat-icon-button
  (click)="active = !active"
  [disableRipple]="active"
  aria-label="search"
>
  <mat-icon>search</mat-icon>
</button>
<input
  placeholder="Press enter to search"
  [(ngModel)]="query"
  (keydown.enter)="search()"
  [matAutocomplete]="auto"
/>

<mat-autocomplete
  autoActiveFirstOption
  #auto="matAutocomplete"
  class="search-box-autocomplete"
  [panelWidth]="290"
  [requireSelection]="false"
  (optionSelected)="onOptionSelected($event)"
>
  @for (option of filteredOptions; track $index) {
  <mat-option [value]="option">{{ option }}</mat-option>
  }
</mat-autocomplete>
