<div class="page-content">
  <h1>Terms of Use</h1>
  <p>Welcome to Super Apparel!</p>
  <p>
    These Terms of Use govern your access to and use of the Super Apparel
    website ("Site"). By accessing or using our Site, you agree to comply with
    and be bound by these terms. If you do not agree with these terms, please do
    not use our Site.
  </p>

  <ol>
    <li>
      <strong>Use of the Site</strong>
      <ul>
        <li>
          <p>
            <strong>Access and Use:</strong> You are permitted to browse our
            Site, view product information, and add items to your cart without
            creating an account. However, to place an order, you will need to
            create an account and log in.
          </p>
        </li>
        <li>
          <p>
            <strong>User Registration:</strong> When creating an account, you
            must provide a valid email address, and your first and last name.
            You are responsible for maintaining the confidentiality of your
            login credentials and for all activities that occur under your
            account.
          </p>
        </li>
      </ul>
    </li>
    <li>
      <strong>Restrictions on Use</strong>
      <ul>
        <li>
          <p>
            You may not use our Site for any unlawful purpose or in any way that
            could harm, disable, overburden, or impair our Site or interfere
            with any other party's use and enjoyment of our Site.
          </p>
        </li>
        <li>
          <p>
            You may not attempt to gain unauthorized access to any portion or
            feature of the Site, or any other systems or networks connected to
            the Site.
          </p>
        </li>
      </ul>
    </li>
    <li>
      <strong>Product Information</strong>
      <ul>
        <li>
          <p>
            We aim to provide accurate and up-to-date information about our
            products. However, we do not guarantee that the product
            descriptions, images, pricing, or other content on the Site are
            error-free, complete, or current.
          </p>
        </li>
      </ul>
    </li>
    <li>
      <strong>Orders and Payments</strong>
      <ul>
        <li>
          <p>
            All orders placed through our Site are subject to acceptance by
            Super Apparel. We reserve the right to refuse or cancel any order
            for any reason.
          </p>
        </li>
        <li>
          <p>
            You agree to provide accurate and complete payment information for
            any purchases made on our Site. All prices are listed in USD unless
            otherwise specified.
          </p>
        </li>
      </ul>
    </li>
    <li>
      <strong>Cookies</strong>
      <ul>
        <li>
          <p>
            We use cookies to maintain your login status and enhance your
            experience on our Site. By using our Site, you consent to our use of
            cookies in accordance with our Privacy Policy.
          </p>
        </li>
      </ul>
    </li>
    <li>
      <strong>Changes to the Terms</strong>
      <ul>
        <li>
          <p>
            We may update these Terms of Use from time to time. We will notify
            you of any changes by posting the new terms on our Site. Your
            continued use of the Site after such changes constitutes your
            acceptance of the new terms.
          </p>
        </li>
      </ul>
    </li>
    <li>
      <strong>Limitation of Liability</strong>
      <ul>
        <li>
          <p>
            Super Apparel will not be liable for any indirect, incidental,
            special, or consequential damages arising out of or in connection
            with your use of the Site.
          </p>
        </li>
      </ul>
    </li>
    <li>
      <strong>Governing Law</strong>
      <ul>
        <li>
          <p>
            These Terms of Use are governed by and construed in accordance with
            the laws applicable in the jurisdiction of our business operation.
          </p>
        </li>
      </ul>
    </li>
  </ol>
</div>
