<div matDialogTitle>Contact Us</div>

<div matDialogContent class="content">
  <app-contact-whatsapp-button></app-contact-whatsapp-button>
  <app-contact-email-button></app-contact-email-button>
</div>

<div matDialogActions align="end">
  <button mat-button mat-dialog-close>Close</button>
</div>
