<!-- <img ngSrc="images/logo.png" width="48" height="48" alt="Super Apparel logo" />
<h2>
  <a href="/">Super Apparel</a>
</h2>
<div class="flex-spacer"></div>
<p>
  1 / F, Unit 1, Building 5, Houfan A District Nianshanli ,Yiwu City, Zhejiang
  Province, China
</p> -->

<div class="logo">
  <img
    ngSrc="images/logo.png"
    width="48"
    height="48"
    alt="Super Apparel logo"
  />
  <h3>
    <a href="/">Super Apparel</a>
  </h3>
</div>

<div class="item">
  <h4>Policy</h4>
  <ul>
    <li>
      <a routerLink="/policy/terms-of-use">Terms of Use</a>
    </li>
    <li>
      <a routerLink="/policy/privacy">Privacy Policy</a>
    </li>
  </ul>
</div>
<div class="item">
  <h4>Social Media</h4>
  <ul>
    <li>
      <a
        target="_blank"
        href="https://www.facebook.com/alice.yang.710667"
        aria-label="facebook"
        >Facebook</a
      >
    </li>
    <li>
      <a
        target="_blank"
        href="https://www.instagram.com/super_apparel111/"
        aria-label="instagram"
        >Instagram</a
      >
    </li>
    <li>
      <a
        target="_blank"
        href="https://github.com/superapparel/"
        aria-label="github"
        >GitHub</a
      >
    </li>
  </ul>
</div>

<div class="item">
  <h4>Address</h4>
  <p>
    1 / F, Unit 1, Building 5, Houfan A District Nianshanli ,Yiwu City, Zhejiang
    Province, China
  </p>
</div>
