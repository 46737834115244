import { Component, ElementRef } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TitleService } from '../../../services/title.service';
import { ProductApiService } from '../../../services/api/product-api.service';
import { ProductImagesSwiperComponent } from '../../../components/product/product-images-swiper/product-images-swiper.component';
import { MatDividerModule } from '@angular/material/divider';
import { DatePipe, Location, NgClass, NgOptimizedImage } from '@angular/common';
import { FileurlPipe } from '../../../pipes/fileurl.pipe';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { HttpParams } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import {
  MatProgressSpinner,
  MatProgressSpinnerModule,
} from '@angular/material/progress-spinner';
import { animate, style, transition, trigger } from '@angular/animations';
import { ProductAttrValueItemComponent } from '../../../components/product/product-attr-value-item/product-attr-value-item.component';
import { MatRippleModule } from '@angular/material/core';
import { ProductPricedAttrValueItemComponent } from '../../../components/product/product-priced-attr-value-item/product-priced-attr-value-item.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { CartService } from '../../../services/cart.service';
import { ApiStatus } from '../../../services/api/status';
import { MessageService } from '../../../services/message.service';

@Component({
  selector: 'app-shopping-product-page',
  standalone: true,
  imports: [
    ProductImagesSwiperComponent,
    MatDividerModule,
    NgOptimizedImage,
    FileurlPipe,
    NgClass,
    MatButtonToggleModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinner,
    ProductAttrValueItemComponent,
    MatRippleModule,
    ProductPricedAttrValueItemComponent,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    MatTooltipModule,
    RouterModule,
    MatProgressSpinnerModule,
    DatePipe,
  ],
  templateUrl: './shopping-product-page.component.html',
  styleUrl: './shopping-product-page.component.scss',
  animations: [
    trigger('content', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('.5s ease-in-out', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class ShoppingProductPageComponent {
  no: string = '';
  queryAttrs: any = null;
  quantity: number = 1;
  constructor(
    private route: ActivatedRoute,
    private title: TitleService,
    private productApi: ProductApiService,
    private location: Location,
    private cartService: CartService,
    private messageService: MessageService
  ) {
    this.title.set('Product');
    this.route.params.subscribe((r) => {
      this.no = r['id'];
      this.selectedAttr = {};
      this.getProductByNO();
    });
    this.route.queryParams.subscribe((r) => {
      this.queryAttrs = r['attrs'] || [];
      this.quantity = parseInt(r['q']) || 1;
      if (this.quantity <= 0) {
        this.quantity = 1;
      }
      this.loadAttrs();
    });
  }

  loading: boolean = false;
  data: any = null;
  selectedAttr: any = {};

  async getProductByNO() {
    this.data = null;
    try {
      this.loading = true;
      const r = await this.productApi.getProductByNO(this.no);
      this.data = r.data;
      if (this.data) {
        this.title.set(this.data.name);
        this.loadAttrs();
      } else {
        this.title.set('Not Found');
      }
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  loadAttrs() {
    var attrs = this.queryAttrs;
    if (!this.data) {
      return;
    }
    if (!attrs) {
      attrs = [];
    } else if (!Array.isArray(attrs)) {
      attrs = [attrs];
    }
    for (const attr of attrs) {
      const id = parseInt(attr);
      if (!id) {
        continue;
      }
      for (const attr of this.data.attrs) {
        for (const value of attr.values) {
          if (value.id === id) {
            this.selectedAttr[attr.id] = id;
            break;
          }
        }
      }
      for (const value of this.data.priced_attr.values) {
        if (value.id === id) {
          this.selectedAttr[this.data.priced_attr.id] = id;
          break;
        }
      }
    }
    // 载入默认的选择，第一个值
    for (const attr of this.data.attrs) {
      if (!this.selectedAttr[attr.id] && attr.values.length > 0) {
        this.selectedAttr[attr.id] = attr.values[0].id;
      }
    }
    if (
      !this.selectedAttr[this.data.priced_attr.id] &&
      this.data.priced_attr.values.length > 0
    ) {
      this.selectedAttr[this.data.priced_attr.id] =
        this.data.priced_attr.values[0].id;
    }
  }

  selectAttrValue(attr: any, id: number) {
    this.selectedAttr[attr.id] = id;

    this.updateParams();
  }

  updateParams() {
    const ids: number[] = [];
    for (const key in this.selectedAttr) {
      if (Object.prototype.hasOwnProperty.call(this.selectedAttr, key)) {
        const element = this.selectedAttr[key];
        ids.push(element);
      }
    }
    const params = new HttpParams().appendAll({
      attrs: ids,
      q: this.quantity,
    });
    this.location.replaceState(location.pathname, params.toString());
  }

  get selectedPrice() {
    if (!this.data || !this.data.priced_attr) {
      return 0;
    }
    const id = this.selectedAttr[this.data?.priced_attr.id];
    if (!id) {
      return 0;
    }
    for (const value of this.data.priced_attr.values) {
      if (value.id === id) {
        return Math.round(value.price * this.quantity * 10) / 10;
      }
    }
    return 0;
  }

  cartLoading: boolean = false;

  async addToCart() {
    if (this.cartLoading) {
      return;
    }
    const attrValueIDs: number[] = [];
    for (const attr of this.data.attrs) {
      if (!this.selectedAttr[attr.id]) {
        this.messageService.open(`Please choose a ${attr.name}`, 'OK');
        return;
      }
      attrValueIDs.push(this.selectedAttr[attr.id]);
    }
    if (!this.selectedAttr[this.data.priced_attr.id]) {
      this.messageService.open(
        `Please choose a ${this.data.priced_attr.name}`,
        'OK'
      );
      return;
    }
    const pricedAttrValueID = this.selectedAttr[this.data.priced_attr.id];
    if (this.quantity <= 0) {
      this.messageService.open(`Quantity must be greater than zero`, 'OK');
      return;
    }
    try {
      this.cartLoading = true;
      const r = await this.cartService.addShoppingCartItem(
        this.data.id,
        attrValueIDs,
        pricedAttrValueID,
        this.quantity
      );
      if (r.status === ApiStatus.ErrProductAttrInvalid) {
        this.messageService.open(
          `Invalid product, please refresh the page.`,
          'OK'
        );
      } else if (r.status !== ApiStatus.OK) {
        this.messageService.open(`Unknown error`, 'OK');
      } else {
        this.messageService
          .open('Added to the cart', 'View Cart')
          ?.onAction()
          .subscribe(() => {
            this.cartService.toggleDrawer();
          });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.cartLoading = false;
      // this.cartService.findShoppingCartItems();
    }
  }
}
