import { Component } from '@angular/core';
import { TitleService } from '../../services/title.service';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { validateFormGroup, validateNonEmptyString } from '../../utils/form';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { UserApiService } from '../../services/api/user-api.service';
import { ApiStatus } from '../../services/api/status';
import { MessageService } from '../../services/message.service';
import { AuthService } from '../../services/auth.service';
import { CartService } from '../../services/cart.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { SendResetPasswordEmailDialogComponent } from '../../components/user/send-reset-password-email-dialog/send-reset-password-email-dialog.component';
import { GoogleRecaptchaComponent } from '../../components/common/google-recaptcha/google-recaptcha.component';
import { CustomReuseStrategy } from '../../route-reuse-strategy';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-login-page',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatCheckboxModule,
    RouterModule,
    MatDividerModule,
    MatDialogModule,
  ],
  templateUrl: './login-page.component.html',
  styleUrl: './login-page.component.scss',
})
export class LoginPageComponent {
  formGroup: FormGroup;
  constructor(
    private titleService: TitleService,
    private fb: FormBuilder,
    private userApi: UserApiService,
    private messageService: MessageService,
    private router: Router,
    private authService: AuthService,
    private cartService: CartService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private platform: Platform
  ) {
    this.titleService.set('Login');

    this.formGroup = this.fb.group({
      email: [
        platform.isBrowser ? localStorage.getItem('user.email') || '' : '',
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      password: ['', [Validators.required, validateNonEmptyString]],
      remember: [false],
    });
  }

  loading: boolean = false;
  submit(e: SubmitEvent) {
    if (this.loading) {
      return;
    }
    const values = validateFormGroup(this.formGroup);
    if (!values) {
      return;
    }
    this.login(values);
  }

  async login(values: any) {
    try {
      this.loading = true;
      const r = await this.userApi.login(
        values.email,
        values.password,
        values.remember
      );
      if (values.remember) {
        localStorage.setItem('user.email', values.email);
      } else {
        localStorage.removeItem('user.email');
      }
      if (r.status === ApiStatus.ErrUserEmailNotFound) {
        this.messageService
          .open('User email not found', 'Signup')
          ?.onAction()
          .subscribe(() => {
            this.router.navigate(['/signup']);
          });
      } else if (r.status === ApiStatus.ErrUserPasswordIncorrect) {
        this.messageService.open('User password incorrect', 'OK');
      } else if (r.status === ApiStatus.ErrUserStatusBanned) {
        this.messageService.open('Sorry, this account has been banned', 'OK');
      } else if (r.status !== ApiStatus.OK) {
        this.messageService.open('Unknown Error', 'OK');
      } else {
        CustomReuseStrategy.clear();
        this.authService.self.set(r.data);
        this.cartService.findShoppingCartItems();
        const url = this.route.snapshot.queryParams['r'] || '/';
        try {
          this.router.navigateByUrl(url, { replaceUrl: true });
        } catch (error) {
          this.router.navigateByUrl('/', { replaceUrl: true });
        }
      }
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
  forgotPassword() {
    this.dialog.open(SendResetPasswordEmailDialogComponent, {
      width: '480px',
      autoFocus: false,
      data: this.formGroup.get('email')?.value,
    });
  }
}
