import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class PromotionApiService {
  constructor(private http: HttpService) {}
  buildurl(path: string, queryMap: any = null): string {
    return this.http.buildurl('/promotion' + path, queryMap);
  }

  findFeaturedProducts() {
    const url = this.buildurl(`/featured/products`);
    return this.http.fget(url);
  }

  findProductCategoryPromotionProducts(id: number) {
    const url = this.buildurl(`/product/category/${id}/promotion/products`);
    return this.http.fget(url);
  }
}
