import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserApiService } from '../../../services/api/user-api.service';
import { MessageService } from '../../../services/message.service';
import { validateFormGroup, validateNonEmptyString } from '../../../utils/form';
import { ApiStatus } from '../../../services/api/status';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-update-account-password-dialog',
  standalone: true,
  imports: [
    MatButtonModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatIconModule,
  ],
  templateUrl: './update-account-password-dialog.component.html',
  styleUrl: './update-account-password-dialog.component.scss',
})
export class UpdateAccountPasswordDialogComponent {
  formGroup: FormGroup;
  constructor(
    private dialogRef: MatDialogRef<UpdateAccountPasswordDialogComponent>,
    private userApi: UserApiService,
    private fb: FormBuilder,
    private messageService: MessageService
  ) {
    this.formGroup = this.fb.group({
      password: [
        '',
        [Validators.required, Validators.pattern(`^(?=.*[A-Z]).{8,}$`)],
      ],
      password2: ['', [Validators.required, validateNonEmptyString]],
    });
  }

  close(r: any = null) {
    this.dialogRef.close(r);
  }

  loading: boolean = false;

  onUpdate() {
    if (this.loading) {
      return;
    }
    const values = validateFormGroup(this.formGroup);
    if (!values) {
      return;
    }
    const password = values.password;
    const password2 = values.password2;
    if (password !== password2) {
      this.formGroup.controls['password2'].setErrors({ invalid: true });
      return;
    }

    this.updateAccountPassword(password);
  }
  async updateAccountPassword(password: string) {
    try {
      this.loading = true;
      const r = await this.userApi.updateAccountPassword({
        password,
      });
      if (r.status !== ApiStatus.OK) {
        this.messageService.open('Unknown Error', 'OK');
      } else {
        this.messageService.open('Password Updated', 'OK');
        this.close();
      }
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
}
