@for (item of items; track $index) {
<app-shopping-order-item-item
  [data]="item"
  [@showup]="{
    value: ':enter',
    params: { duration: 500, delay: $index * 150 }
  }"
>
  @for (extra of item.extra; track $index) {
  <app-shopping-order-item-item
    [data]="extra"
    [noTitle]="true"
  ></app-shopping-order-item-item>
  }
</app-shopping-order-item-item>
}
