import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productPrice',
  standalone: true,
})
export class ProductPricePipe implements PipeTransform {
  transform(data: any, ...args: unknown[]): string {
    var min = Infinity;
    var max = 0;
    for (const value of data.priced_attr.values) {
      if (min > value.price) {
        min = value.price;
      }
      if (max < value.price) {
        max = value.price;
      }
    }
    if (min === max) {
      return `${min.toFixed(1)}`;
    }
    return `${min.toFixed(1)} - ${max.toFixed(1)}`;
  }
}
