import { Component, OnInit } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HeaderComponent } from '../../components/common/header/header.component';
import { FooterComponent } from '../../components/common/footer/footer.component';
import { HomeSection1Component } from '../../components/home/home-section-1/home-section-1.component';
import { TitleService } from '../../services/title.service';
import { HomeSectionFeaturedProductsComponent } from '../../components/home/home-section-featured-products/home-section-featured-products.component';
import { ProductApiService } from '../../services/api/product-api.service';
import { HomeSectionCategoryComponent } from '../../components/home/home-section-category/home-section-category.component';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-home-page',
  standalone: true,
  imports: [
    HomeSection1Component,
    HomeSectionFeaturedProductsComponent,
    HomeSectionCategoryComponent,
    MatButtonModule,
    RouterModule,
  ],
  templateUrl: './home-page.component.html',
  styleUrl: './home-page.component.scss',
})
export class HomePageComponent implements OnInit {
  constructor(
    private title: TitleService,
    private productApi: ProductApiService,
    private route: ActivatedRoute
  ) {
    this.title.set(undefined, this.route);
  }

  ngOnInit(): void {
    this.findProductCategories();
  }

  categories: any[] = [];
  async findProductCategories() {
    try {
      const r = await this.productApi.findProductCategories();
      this.categories = r.data || [];
    } catch (error) {}
  }
}
