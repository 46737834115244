<div class="page-content">
  <h1>Account Setting</h1>

  <div class="content">
    <mat-list>
      <h3 mat-subheader>Fullname</h3>
      <mat-list-item matRipple (click)="updateName()">
        <div matListItemTitle>First Name</div>
        <div matListItemLine>{{ authService.self()?.fname }}</div>
      </mat-list-item>
      <mat-list-item matRipple (click)="updateName()">
        <div matListItemTitle>Last Name</div>
        <div matListItemLine>{{ authService.self()?.lname }}</div>
      </mat-list-item>
    </mat-list>
    <mat-divider></mat-divider>
    <mat-list>
      <h3 mat-subheader>Email</h3>
      <mat-list-item matRipple (click)="updateEmail()">
        @if (authService.self()?.email_verified) {
        <mat-icon
          class="verified"
          matListItemIcon
          matTooltip="The email address is verified"
          >verified</mat-icon
        >
        }@else{
        <mat-icon
          class="unverified"
          matListItemIcon
          matTooltip="The email address is not verified"
          >warning</mat-icon
        >
        }
        <div matListItemTitle>{{ authService.self()?.email }}</div>
      </mat-list-item>
    </mat-list>
    <mat-divider></mat-divider>

    <mat-list>
      <h3 mat-subheader>Password</h3>
      <mat-list-item matRipple (click)="updatePassword()">
        <mat-icon matListItemIcon>lock</mat-icon>
        <div matListItemTitle>********</div>
      </mat-list-item>
    </mat-list>
  </div>
</div>
