<button mat-stroked-button [matMenuTriggerFor]="menu">
  <div class="inner" [ngClass]="{ active: data }">
    {{ data?.name || "Category" }}
    @if (data) {
    <a mat-icon-button (click)="clear($event)">
      <mat-icon>close</mat-icon>
    </a>
    }@else{
    <mat-icon>chevron_right</mat-icon>
    }
  </div>
</button>
<mat-menu #menu="matMenu">
  @for (item of items; track $index) {
  <button mat-menu-item (click)="select(item)" style="min-width: 190px">
    {{ item.name }}
  </button>
  }
</mat-menu>
