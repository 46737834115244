import { Component, HostListener, OnInit } from '@angular/core';
import { ProductApiService } from '../../../services/api/product-api.service';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { TitleService } from '../../../services/title.service';
import { DatePipe, DecimalPipe } from '@angular/common';
import { ProductListItemComponent } from '../../../components/product/product-list-item/product-list-item.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-shopping-product-listing-page',
  standalone: true,
  imports: [
    DatePipe,
    ProductListItemComponent,
    DecimalPipe,
    MatProgressSpinnerModule,
    RouterModule,
    MatButtonModule,
  ],
  templateUrl: './shopping-product-listing-page.component.html',
  styleUrl: './shopping-product-listing-page.component.scss',
})
export class ShoppingProductListingPageComponent implements OnInit {
  constructor(
    private productApi: ProductApiService,
    private route: ActivatedRoute,
    private titleService: TitleService
  ) {
    this.titleService.set('Product List', this.route);
    this.route.params.subscribe((r) => {
      this.page = 1;
      this.getProductList(r['id']);
    });
  }

  ngOnInit(): void {}

  loading: boolean = false;
  data: any;
  items: any[] = [];
  page: number = 1;
  pageSize: number = 20;
  total: number = 0;
  async getProductList(id: string) {
    try {
      this.loading = true;
      const r = await this.productApi.getProductList(id);
      this.data = r.data;
      if (this.data) {
        this.titleService.set(this.data.name, this.route);
        this.findProductListItems(this.data.id);
      } else {
        this.titleService.set('Not Found', this.route);
      }
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  itemsLoading: boolean = false;
  async findProductListItems(id: string) {
    if (this.itemsLoading) {
      return;
    }
    try {
      this.itemsLoading = true;
      const r = await this.productApi.findProductListItems(id, {
        page: this.page,
        pageSize: this.pageSize,
      });
      this.total = r.data.total;
      if (this.page === 1) {
        this.items = r.data.list;
      } else {
        this.items = this.items.concat(r.data.list);
      }
      this.page += 1;
    } catch (error) {
    } finally {
      this.itemsLoading = false;
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const windowHeight =
      'innerHeight' in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;

    const documentHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );

    if (windowHeight + window.scrollY >= documentHeight - 200 && this.hasMore) {
      this.findProductListItems(this.data.id);
    }
  }

  get hasMore(): boolean {
    return this.items.length < this.total;
  }
}
