<div mat-dialog-title>
  {{ data.title }}
</div>
<div mat-dialog-content>
  @for (m of data.messages; track $index) {
  <p>{{ m }}</p>
  }
</div>

<div mat-dialog-actions align="end">
  <button mat-dialog-close mat-button>Close</button>
  @for (action of data.actions; track $index) {
  <button mat-flat-button mat-dialog-close (click)="action.onClick()">
    {{ action.text }}
  </button>
  }
</div>
