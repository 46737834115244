import { Component, Input } from '@angular/core';
import { ShoppingOrderItemItemComponent } from '../shopping-order-item-item/shopping-order-item-item.component';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-shopping-order-item-list',
  standalone: true,
  imports: [ShoppingOrderItemItemComponent],
  templateUrl: './shopping-order-item-list.component.html',
  styleUrl: './shopping-order-item-list.component.scss',
  animations: [
    trigger('showup', [
      transition(
        ':enter',
        [
          style({ opacity: 0, transform: 'translateX(100px)' }),
          animate(
            '{{duration}}ms {{delay}}ms ease-in-out',
            style({ opacity: 1, transform: 'translateX(0px)' })
          ),
        ],
        { params: { duration: 500, delay: 250 } }
      ),
    ]),
  ],
})
export class ShoppingOrderItemListComponent {
  @Input({ required: true }) items: any[] = [];
}
