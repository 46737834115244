<ul>
  <li>
    <a aria-label="go to home page" routerLink="/">Home</a>
  </li>
  <li>
    <a aria-label="go to products page" routerLink="/shopping/products"
      >Products</a
    >
  </li>
  <li>
    <button aria-label="open contact dialog" (click)="openContactDialog()">
      Contact
    </button>
  </li>
  <li>
    <a aria-label="go to faq page" routerLink="/faq">FAQ</a>
  </li>
  <li>
    <a aria-label="go to about page" routerLink="/about">About</a>
  </li>
</ul>
