<a
  [routerLink]="['/shopping/order', data.id]"
  aria-label="go to order detail page"
  matRipple
>
  <div class="no">
    <h5>{{ data.id }}</h5>
    <span class="date">{{ data.created_time | date : "short" }}</span>
  </div>
  <div class="status">
    <app-shopping-order-item-preview
      [items]="data.items"
    ></app-shopping-order-item-preview>
    <app-shopping-order-status
      [status]="data.status"
    ></app-shopping-order-status>
  </div>
  <div class="price">
    <div>
      Quantity: <span class="quantity">{{ data.quantity }}</span>
    </div>
    <div class="right">
      <span
        >$ <span class="price">{{ data.price }}</span></span
      >
      + @if (data.status!=='Pending') {
      <span
        >$ <span class="price">{{ data.shipping_fee }}</span>
      </span>
      }@else {
      <span class="tbd">TBD</span>

      } = @if (data.status!=='Pending') {
      <span
        >$ <span class="price">{{ data.shipping_fee + data.price }}</span>
      </span>
      }@else {
      <span class="tbd">TBD</span>
      }
    </div>
  </div>
</a>
