<div class="name">
  <span class="name"> {{ data.fname }} {{ data.lname }}</span>
  <span class="phone">
    <mat-icon inline>call</mat-icon>
    {{ data.phone }}
  </span>
  <ng-content></ng-content>
</div>
<div class="address">
  <span class="address">{{ data.address }}</span>
  <span class="zipcode">({{ data.zipcode }}), </span>
  <span class="country">{{ data.country }}</span>
</div>
