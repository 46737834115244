<img
  class="logo"
  ngSrc="images/logo.png"
  width="400"
  height="400"
  alt="Super Apparel logo"
/>

<h1 [@showup]="animationParams1">Super Apparel</h1>
<p [@showup]="animationParams2">
  We're a China-based supplier with 6 years of experience in exporting apparel,
  bags, and accessories to the U.S. market. We deliver products that align with
  American trends while maintaining high quality and competitive pricing.
</p>
<p [@showup]="animationParams3">Please contact us if you have any needs.</p>
<button
  mat-raised-button
  (click)="openContactDialog()"
  [@showup]="animationParams4"
>
  Contact Us
</button>
