<div class="page-content">
  <form [formGroup]="formGroup" (ngSubmit)="submit($event)" autocomplete="off">
    <h1>Create an Account</h1>
    <h2>Welcome to <span>Super</span><span>Apparel</span> 👋</h2>
    <div class="row">
      <mat-form-field appearance="outline">
        <mat-label>First Name</mat-label>
        <input matInput placeholder="" formControlName="fname" maxlength="60" />
        <mat-error>Please enter first name.</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Last Name</mat-label>
        <input matInput placeholder="" formControlName="lname" maxlength="60" />
        <mat-error>Please enter last name.</mat-error>
      </mat-form-field>
    </div>
    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input
        matInput
        placeholder=""
        formControlName="email"
        (ngModelChange)="formGroup.get('email')?.setErrors(null)"
      />
      <mat-icon matPrefix>mail</mat-icon>
      <mat-error>Please enter a valid email.</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input
        matInput
        placeholder=""
        type="password"
        formControlName="password"
      />
      <mat-icon matPrefix>lock</mat-icon>
      <!-- <mat-hint
        >Must be at least 8 characters with at least one uppercase
        letter.</mat-hint
      > -->
      <mat-error>At least 8 characters and 1 uppercase letter..</mat-error>
    </mat-form-field>

    <p class="policy">
      * By signing up, you agree to our
      <a routerLink="/policy/terms-of-use" aria-label="Terms of Use"
        >Terms of Use</a
      >
      and
      <a routerLink="/policy/privacy" aria-label="Privacy Policy"
        >Privacy Policy</a
      >
    </p>
    <button mat-flat-button>
      @if (loading) {
      <mat-spinner [diameter]="30"></mat-spinner>
      }@else {
      <span>Signup</span>
      }
    </button>
  </form>
</div>
