import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { HeaderComponent } from '../../components/common/header/header.component';
import { FooterComponent } from '../../components/common/footer/footer.component';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ContactFabComponent } from '../../components/common/contact-fab/contact-fab.component';
import {
  MatDrawer,
  MatDrawerContent,
  MatSidenavModule,
} from '@angular/material/sidenav';
import { DrawerComponent } from '../../components/common/drawer/drawer.component';
import { NgClass } from '@angular/common';
import { ShoppingCartDrawerComponent } from '../../components/shopping/shopping-cart-drawer/shopping-cart-drawer.component';
import { CartService } from '../../services/cart.service';
@Component({
  selector: 'app-main-layout-page',
  standalone: true,
  imports: [
    HeaderComponent,
    FooterComponent,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    ContactFabComponent,
    MatSidenavModule,
    DrawerComponent,
    NgClass,
    ShoppingCartDrawerComponent,
  ],
  templateUrl: './main-layout-page.component.html',
  styleUrl: './main-layout-page.component.scss',
})
export class MainLayoutPageComponent implements AfterViewInit {
  @ViewChild('leftDrawer') leftDrawerRef!: MatDrawer;
  @ViewChild('rightDrawer') rightDrawerRef!: MatDrawer;
  constructor(private router: Router, private cartService: CartService) {
    // 监听路由变化
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.leftDrawerRef?.close();
        this.rightDrawerRef?.close();
      }
    });
  }
  ngAfterViewInit(): void {
    this.cartService.setDrawer(this.rightDrawerRef);
  }
  onDrawerToggled(isOpened: boolean) {
    document.body.style.overflow = isOpened ? 'hidden' : 'auto';
  }
}
