import { Component, OnInit } from '@angular/core';
import { TitleService } from '../../../services/title.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthService } from '../../../services/auth.service';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../../environments/environment';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { UpdateAccountEmailDialogComponent } from '../../../components/user/update-account-email-dialog/update-account-email-dialog.component';
import { UpdateAccountNameDialogComponent } from '../../../components/user/update-account-name-dialog/update-account-name-dialog.component';
import { UpdateAccountPasswordDialogComponent } from '../../../components/user/update-account-password-dialog/update-account-password-dialog.component';

@Component({
  selector: 'app-account-setting-page',
  standalone: true,
  imports: [
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatDividerModule,
    MatListModule,
    MatIconModule,
    MatRippleModule,
    MatTooltipModule,
    MatDialogModule,
  ],
  templateUrl: './account-setting-page.component.html',
  styleUrl: './account-setting-page.component.scss',
})
export class AccountSettingPageComponent implements OnInit {
  constructor(
    private titleService: TitleService,
    public authService: AuthService,
    private platform: Platform,
    private dialog: MatDialog
  ) {
    this.titleService.set('Account Settings');
  }

  ngOnInit(): void {
    if (environment.isDevelopment && !this.platform.isBrowser) {
      return;
    }
    this.authService.getSelf();
  }

  updateName() {
    this.dialog.open(UpdateAccountNameDialogComponent, {
      width: '480px',
      autoFocus: false,
    });
  }

  updateEmail() {
    this.dialog.open(UpdateAccountEmailDialogComponent, {
      width: '480px',
      autoFocus: false,
    });
  }

  updatePassword() {
    this.dialog.open(UpdateAccountPasswordDialogComponent, {
      width: '480px',
      autoFocus: false,
    });
  }
}
