@if (data.image) {
<img
  ngSrc="{{ data.image | fileurl }}"
  width="140"
  height="140"
  alt="attr image"
/>
<button mat-icon-button aria-label="view image" (click)="viewImage($event)">
  <mat-icon>zoom_in</mat-icon>
</button>
} @if(data.name){
<span>{{ data.name | titlecase }}</span>
}
