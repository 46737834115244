import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { ProductApiService } from '../../../services/api/product-api.service';
import { HomeProductItemComponent } from '../../product/home-product-item/home-product-item.component';
import { RouterModule } from '@angular/router';
import { SwiperOptions } from 'swiper/types';
import {
  Mousewheel,
  Navigation,
  Pagination,
  A11y,
  Autoplay,
  Scrollbar,
  FreeMode,
} from 'swiper/modules';
import { Platform } from '@angular/cdk/platform';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { PromotionApiService } from '../../../services/api/promotion-api.service';

@Component({
  selector: 'app-home-section-featured-products',
  standalone: true,
  imports: [
    MatDividerModule,
    HomeProductItemComponent,
    RouterModule,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: './home-section-featured-products.component.html',
  styleUrl: './home-section-featured-products.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HomeSectionFeaturedProductsComponent implements OnInit {
  constructor(
    private promotionApi: PromotionApiService,
    private platform: Platform
  ) {}

  ngOnInit(): void {
    this.findFeaturedProducts();
  }

  get isBrowser() {
    return this.platform.isBrowser;
  }

  products: any[] = [];
  loading: boolean = false;
  async findFeaturedProducts() {
    try {
      this.loading = true;
      const r = await this.promotionApi.findFeaturedProducts();
      this.products = r.data || [];
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
}
