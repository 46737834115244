<div class="page-content">
  <h1>404</h1>
  <p>Page Not Found</p>
  <p>The page you are looking for doesn't exist.</p>
  <a
    routerLink="/"
    aria-label="go to home page"
    mat-stroked-button
    [replaceUrl]="true"
    >Back to Home</a
  >
</div>
