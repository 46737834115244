<div class="page-content">
  <h1>Privacy Policy</h1>
  <p>
    Super Apparel respects your privacy. This Privacy Policy explains how we
    collect, use, and protect your information when you use our Site.
  </p>

  <ol>
    <li>
      <strong>Information We Collect</strong>
      <ul>
        <li>
          <p>
            <strong>Personal Information:</strong> When you register on our
            Site, we collect your email address, first name, and last name. This
            information is necessary for creating your account and providing our
            services.
          </p>
        </li>
        <li>
          <p>
            <strong>Cookies:</strong> We use cookies to maintain your login
            status and improve your experience on our Site.
          </p>
        </li>
      </ul>
    </li>
    <li>
      <strong>How We Use Your Information</strong>
      <ul>
        <li>
          <p>
            <strong>Account Creation and Maintenance:</strong> We use your
            personal information to create and manage your account on our Site.
          </p>
        </li>
        <li>
          <p>
            <strong>Communication:</strong> We may use your email address to
            send you information about your account or updates related to your
            orders.
          </p>
        </li>
        <li>
          <p>
            <strong>Cookies:</strong> Cookies are used to keep you logged in and
            to facilitate your navigation through our Site.
          </p>
        </li>
      </ul>
    </li>
    <li>
      <strong>Information Sharing</strong>
      <ul>
        <li>
          <p>
            <strong>No Third-Party Sharing:</strong> We do not share, sell,
            rent, or trade your personal information with any third parties.
            Your information is used solely for the purpose of providing
            services on our Site.
          </p>
        </li>
      </ul>
    </li>
    <li>
      <strong>Data Security</strong>
      <ul>
        <li>
          <p>
            We take reasonable precautions to protect your personal information
            from unauthorized access, use, or disclosure. However, no method of
            transmission over the Internet or electronic storage is 100% secure.
          </p>
        </li>
      </ul>
    </li>
    <li>
      <strong>Data Retention</strong>
      <ul>
        <li>
          <p>
            We retain your personal information only as long as necessary to
            fulfill the purposes outlined in this Privacy Policy or as required
            by law.
          </p>
        </li>
      </ul>
    </li>
    <li>
      <strong>Your Rights</strong>
      <ul>
        <li>
          <p>
            You have the right to access, correct, or delete your personal
            information at any time by logging into your account.
          </p>
        </li>
      </ul>
    </li>
    <li>
      <strong>Changes to This Privacy Policy</strong>
      <ul>
        <li>
          <p>
            We may update this Privacy Policy periodically. We will notify you
            of any changes by posting the updated policy on our Site.
          </p>
        </li>
      </ul>
    </li>
    <li>
      <strong>Contact Us</strong>
      <ul>
        <li>
          <p>
            If you have any questions or concerns about this Privacy Policy,
            please contact us via the contact form on our Site.
          </p>
        </li>
      </ul>
    </li>
  </ol>
</div>
