import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FileurlPipe } from '../../../pipes/fileurl.pipe';
import { DecimalPipe, NgOptimizedImage, TitleCasePipe } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { ShoppingApiService } from '../../../services/api/shopping-api.service';
import { CartService } from '../../../services/cart.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TitlecasePipe } from '../../../pipes/titlecase.pipe';

@Component({
  selector: 'app-shopping-cart-item',
  standalone: true,
  imports: [
    FileurlPipe,
    NgOptimizedImage,
    MatDividerModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    DecimalPipe,
    MatInputModule,
    FormsModule,
    NzInputModule,
    NzInputNumberModule,
    MatTooltipModule,
    TitlecasePipe,
  ],
  templateUrl: './shopping-cart-item.component.html',
  styleUrl: './shopping-cart-item.component.scss',
})
export class ShoppingCartItemComponent implements OnChanges {
  @Input({ required: true }) data: any;
  @HostBinding('class.no-title')
  @Input()
  noTitle: boolean = false;
  @HostBinding('class.offline')
  get offline() {
    return this.data.product.status === 'Offline';
  }
  @Output() onRemove = new EventEmitter<any>();

  constructor(private cartService: CartService) {}

  quantity: number = 1;
  ngOnChanges(changes: SimpleChanges): void {
    this.quantity = this.data.quantity;
  }

  get queryAttrs(): number[] {
    const attrs = this.data.attr_value_ids.slice();
    attrs.push(this.data.priced_attr_value_id);
    return attrs;
  }
  onQuantityChanged() {
    if (!this.quantity) {
      return;
    }
    const quantity = this.quantity;
    setTimeout(() => {
      if (this.quantity !== quantity) {
        return;
      }
      this.updateShoppingCartItem(this.quantity);
    }, 1000);
  }

  async updateShoppingCartItem(quantity: number) {
    try {
      await this.cartService.updateShoppingCartItem(this.data.id, quantity);
    } catch (error) {}
  }
}
