import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  Input,
} from '@angular/core';
import { NzImageModule, NzImageService } from 'ng-zorro-antd/image';
import { FileurlPipe } from '../../../pipes/fileurl.pipe';
import { ProductImagesSwiperComponent } from '../product-images-swiper/product-images-swiper.component';
import { NgOptimizedImage } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { getRandomNumber } from '../../../utils/number';
import { FileApiService } from '../../../services/api/file-api.service';
import { ProductPricePipe } from '../../../pipes/product-price.pipe';
import { RouterModule } from '@angular/router';
import { MatBadgeModule } from '@angular/material/badge';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-product-list-item',
  standalone: true,
  imports: [
    NzImageModule,
    FileurlPipe,
    ProductImagesSwiperComponent,
    NgOptimizedImage,
    MatIconModule,
    MatButtonModule,
    RouterModule,
    ProductPricePipe,
    MatBadgeModule,
  ],
  templateUrl: './product-list-item.component.html',
  styleUrl: './product-list-item.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  animations: [
    trigger('scrollAnimation', [
      state(
        'hidden',
        style({
          opacity: 0,
        })
      ),
      state(
        'visible',
        style({
          opacity: 1,
        })
      ),
      transition('hidden => visible', [animate('0.25s ease-in-out')]),
    ]),
  ],
  host: {
    '[@scrollAnimation]': 'elementState', // Bind the animation to the host
  },
})
export class ProductListItemComponent implements AfterViewInit {
  @Input() index: number = 0;
  @Input({ required: true }) data: any;

  constructor(
    private nzImageService: NzImageService,
    private fileApi: FileApiService,
    private el: ElementRef,
    private platform: Platform
  ) {}

  view(i: number) {
    const images = [];
    for (const img of this.data.product.images) {
      images.push({
        src: this.fileApi.fileurl(img),
      });
    }
    const ref = this.nzImageService.preview(images);
    ref.switchTo(i);
  }

  elementState = 'hidden'; // Initial state

  ngAfterViewInit(): void {
    if (!this.platform.isBrowser) {
      return;
    }
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.elementState = 'visible'; // Trigger the animation
          observer.unobserve(entry.target); // Stop observing once the animation has been triggered
        }
      });
    });

    observer.observe(this.el.nativeElement); // Target the element you want to animate
  }
}
