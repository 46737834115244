import { Component } from '@angular/core';
import { TitleService } from '../../services/title.service';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { validateFormGroup, validateNonEmptyString } from '../../utils/form';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Router, RouterModule } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { UserApiService } from '../../services/api/user-api.service';
import { MessageService } from '../../services/message.service';
import { ApiStatus } from '../../services/api/status';
import { AuthService } from '../../services/auth.service';
import { CartService } from '../../services/cart.service';
import { CustomReuseStrategy } from '../../route-reuse-strategy';
@Component({
  selector: 'app-signup-page',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatCheckboxModule,
    RouterModule,
    MatDividerModule,
  ],
  templateUrl: './signup-page.component.html',
  styleUrl: './signup-page.component.scss',
})
export class SignupPageComponent {
  formGroup: FormGroup;
  constructor(
    private titleService: TitleService,
    private fb: FormBuilder,
    private userApi: UserApiService,
    private messageService: MessageService,
    private router: Router,
    private authService: AuthService,
    private cartService: CartService
  ) {
    this.titleService.set('Signup');
    this.formGroup = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      fname: ['', [Validators.required, validateNonEmptyString]],
      lname: ['', [Validators.required, validateNonEmptyString]],
      password: [
        '',
        [Validators.required, Validators.pattern(`^(?=.*[A-Z]).{8,}$`)],
      ],
    });
  }

  loading: boolean = false;
  submit(e: SubmitEvent) {
    if (this.loading) {
      return;
    }
    const values = validateFormGroup(this.formGroup);
    if (!values) {
      return;
    }
    this.signup(values);
  }

  async signup(values: any) {
    try {
      this.loading = true;
      const r = await this.userApi.signup(
        values.email,
        values.fname,
        values.lname,
        values.password
      );
      if (r.status === ApiStatus.ErrUserEmailDuplicated) {
        this.messageService
          .open('User email already exists', 'Login')
          ?.onAction()
          .subscribe(() => {
            this.router.navigate(['/login'], { replaceUrl: true });
          });
      } else if (r.status !== ApiStatus.OK) {
        this.messageService.open('Unknown Error', 'OK');
      } else {
        CustomReuseStrategy.clear();
        this.router.navigate(['/'], { replaceUrl: true });
        this.authService.self.set(r.data);
        this.cartService.findShoppingCartItems();
      }
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
}
