<div class="page-content">
  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <h1>Reset Your Password</h1>
    <mat-form-field appearance="fill">
      <mat-label>New Password</mat-label>
      <input
        matInput
        placeholder=""
        formControlName="password"
        type="password"
      />
      <mat-icon matPrefix>lock</mat-icon>
      <mat-hint>8+ characters, 1 uppercase letter.</mat-hint>
      <!-- <mat-error>8+ characters, 1 uppercase letter.</mat-error> -->
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Confirm Password</mat-label>
      <input
        matInput
        placeholder=""
        formControlName="password2"
        type="password"
      />
      <mat-icon matPrefix>lock</mat-icon>
      <mat-hint>Repeat your password</mat-hint>
      <mat-error>Passwords do not match</mat-error>
    </mat-form-field>

    <button mat-flat-button>
      @if (loading) {
      <mat-spinner [diameter]="30"></mat-spinner>
      }@else {
      <span>Submit</span>
      }
    </button>
  </form>
</div>
