<mat-list>
  <div mat-subheader>Summary</div>
  <mat-list-item>
    <mat-icon fontSet="material-icons-outlined" matListItemIcon
      >category</mat-icon
    >
    <div matListItemTitle class="title">
      <span>Item Quantity</span>
      <span class="quantity">{{ cartService.quantity() | number }} </span>
    </div>
  </mat-list-item>
  <mat-list-item>
    <mat-icon fontSet="material-icons-outlined" matListItemIcon
      >category</mat-icon
    >
    <div matListItemTitle class="title">
      <span>Product Count</span>
      <span class="quantity"
        >{{ cartService.groupedItems().length | number }}
      </span>
    </div>
  </mat-list-item>
  <mat-list-item>
    <mat-icon fontSet="material-icons-outlined" matListItemIcon>paid</mat-icon>
    <div matListItemTitle class="title">
      <span>Subtotal</span>
      <span
        >$
        <span class="price">{{ cartService.price() | number : ".1" }}</span>
        @if (cartService.originalPrice()>cartService.price()) {
        <span class="original_price"
          >({{ cartService.originalPrice() | number : ".1" }})</span
        >
        }
      </span>
    </div>
  </mat-list-item>
  <mat-list-item>
    <mat-icon fontSet="material-icons-outlined" matListItemIcon
      >directions_boat</mat-icon
    >
    <div matListItemTitle class="title">
      <span>Shipping Cost</span>
      <span>TBD</span>
    </div>
  </mat-list-item>
</mat-list>

<mat-divider></mat-divider>
<app-user-address-select
  (onAddrSelected)="addr = $event"
></app-user-address-select>
<mat-divider></mat-divider>
<mat-form-field>
  <mat-label>Remark</mat-label>
  <textarea
    matInput
    placeholder="Add any notes or special instructions for your order"
    [(ngModel)]="remark"
  ></textarea>
</mat-form-field>

<div class="checkout">
  <button
    mat-flat-button
    [disabled]="!cartService.groupedItems().length || !addr"
    (click)="createOrder()"
  >
    @if (loading) {
    <mat-spinner [diameter]="26"></mat-spinner>
    }@else{ Create Order }
  </button>
</div>
<div class="info">
  <mat-icon fontSet="material-icons-outlined">info</mat-icon>
  <span>
    Please contact us after placing your order. We will review your order and
    add the shipping cost as soon as possible.
  </span>
</div>
