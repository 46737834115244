<div matDialogTitle>Reset Password</div>

<div matDialogContent>
  <form [formGroup]="formGroup">
    <mat-form-field appearance="fill">
      <mat-label>Email</mat-label>
      <input matInput placeholder="" formControlName="email" />
      <mat-icon matPrefix>mail</mat-icon>
      <mat-hint>We will send a password reset email to you</mat-hint>
      <mat-error>Please input a valid email address</mat-error>
    </mat-form-field>
    <app-google-recaptcha></app-google-recaptcha>
  </form>
</div>

<div matDialogActions align="end">
  <button mat-dialog-close mat-button>Cancel</button>
  <button mat-flat-button (click)="onSend()">Send</button>
</div>
