import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { validateFormGroup } from '../../../utils/form';
import { UserApiService } from '../../../services/api/user-api.service';
import { ApiStatus } from '../../../services/api/status';
import { MessageService } from '../../../services/message.service';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { GoogleRecaptchaComponent } from '../../common/google-recaptcha/google-recaptcha.component';
declare var grecaptcha: any;

@Component({
  selector: 'app-send-reset-password-email-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    GoogleRecaptchaComponent,
  ],
  templateUrl: './send-reset-password-email-dialog.component.html',
  styleUrl: './send-reset-password-email-dialog.component.scss',
})
export class SendResetPasswordEmailDialogComponent {
  formGroup: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<SendResetPasswordEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: string,
    private fb: FormBuilder,
    private userApi: UserApiService,
    private messageService: MessageService,
    private router: Router
  ) {
    this.formGroup = this.fb.group({
      email: [data || '', [Validators.required, Validators.email]],
    });
  }

  close(r: any = null) {
    this.dialogRef.close(r);
  }

  onSend() {
    if (this.loading) {
      return;
    }
    const values = validateFormGroup(this.formGroup);
    if (!values) {
      return;
    }
    const token = grecaptcha.getResponse();
    if (!token) {
      this.messageService.open(
        'Please complete the CAPTCHA to verify you are not a robot.',
        'OK'
      );
      return;
    }
    this.sendResetPasswordEmail(values.email, token);
  }

  loading: boolean = false;
  async sendResetPasswordEmail(email: string, token: string) {
    try {
      this.loading = true;
      const r = await this.userApi.sendResetPasswordEmail({ email, token });
      if (r.status === ApiStatus.ErrRecaptchaTokenFailed) {
        this.messageService.open('Please complete the CAPTCHA', 'OK');
        grecaptcha.reset();
      } else if (r.status === ApiStatus.ErrUserEmailNotFound) {
        this.messageService
          .open('This email is not registered', 'Signup')
          ?.onAction()
          .subscribe(() => {
            this.close();
            this.router.navigate(['/signup']);
          });
      } else if (r.status !== ApiStatus.OK) {
        this.messageService.open('Unknown Error', 'OK');
      } else {
        this.messageService.open('Please check your email', 'OK');
        this.close();
      }
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
}
