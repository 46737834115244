import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ProductApiService } from '../../../services/api/product-api.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgClass } from '@angular/common';
@Component({
  selector: 'app-product-category-select',
  standalone: true,
  imports: [MatButtonModule, MatMenuModule, MatIconModule, NgClass],
  templateUrl: './product-category-select.component.html',
  styleUrl: './product-category-select.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ProductCategorySelectComponent,
    },
  ],
})
export class ProductCategorySelectComponent
  implements OnInit, ControlValueAccessor
{
  id: number = 0;
  data: any = {};

  items: any[] = [];
  constructor(private productApi: ProductApiService) {}
  ngOnInit(): void {
    this.findProductCategories();
  }

  loading: boolean = false;
  async findProductCategories() {
    try {
      this.loading = true;
      const r = await this.productApi.findProductCategories();
      this.items = r.data || [];
      this.check();
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  select(data: any) {
    this.data = data;
    this.id = data?.id || 0;
    this.onFormChange(this.id);
  }

  clear(e: any) {
    e.preventDefault();
    e.stopPropagation();
    this.data = null;
    this.id = 0;
    this.onFormChange(0);
  }

  check() {
    this.data = null;
    if (!this.id) {
      return;
    }
    for (const item of this.items) {
      if (item.id === this.id) {
        this.data = item;
        break;
      }
    }
  }

  writeValue(v: number) {
    this.id = v;
    this.check();
  }

  onFormChange = (v: any) => {};

  onFormTouched = () => {};

  registerOnChange(onChange: any) {
    this.onFormChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onFormTouched = onTouched;
  }

  disabled: boolean = false;
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
