import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ContactDialogComponent } from '../../common/contact-dialog/contact-dialog.component';
import { NgOptimizedImage } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-home-section-1',
  standalone: true,
  imports: [MatButtonModule, MatDialogModule, NgOptimizedImage],
  templateUrl: './home-section-1.component.html',
  styleUrl: './home-section-1.component.scss',
  animations: [
    trigger('showup', [
      transition(
        ':enter',
        [
          style({ opacity: 0, transform: 'translateY(20px)' }),
          animate(
            '{{duration}}ms {{delay}}ms ease-in-out',
            style({ opacity: 1, transform: 'translateY(0px)' })
          ),
        ],
        { params: { duration: 500, delay: 250 } }
      ),
    ]),
  ],
})
export class HomeSection1Component {
  animationParams1 = { value: ':enter', params: { duration: 500, delay: 100 } };
  animationParams2 = { value: ':enter', params: { duration: 500, delay: 300 } };
  animationParams3 = { value: ':enter', params: { duration: 500, delay: 500 } };
  animationParams4 = {
    value: ':enter',
    params: { duration: 500, delay: 700 },
  };

  constructor(private dialog: MatDialog) {}

  openContactDialog() {
    this.dialog.open(ContactDialogComponent, {
      width: '200px',
      autoFocus: false,
    });
  }
}
