import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { environment } from '../../../../environments/environment';
import { ContactWhatsappButtonComponent } from '../contact-whatsapp-button/contact-whatsapp-button.component';
import { ContactEmailButtonComponent } from '../contact-email-button/contact-email-button.component';
@Component({
  selector: 'app-contact-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    ContactWhatsappButtonComponent,
    ContactEmailButtonComponent,
  ],
  templateUrl: './contact-dialog.component.html',
  styleUrl: './contact-dialog.component.scss',
})
export class ContactDialogComponent {
  constructor(private dialogRef: MatDialogRef<ContactDialogComponent>) {}
  close(r: any = null) {
    this.dialogRef.close(r);
  }
}
