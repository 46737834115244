@if (authService.self()) {
<button mat-button aria-label="open account setting" [matMenuTriggerFor]="menu">
  <span class="name">
    {{ authService.self().fname }} {{ authService.self().lname }}
  </span>
</button>
<mat-menu #menu>
  <menu
    mat-menu-item
    routerLink="/shopping/cart"
    aria-label="go to cart page"
    style="min-width: 140px"
  >
    <mat-icon fontSet="material-icons-outlined">shopping_bag</mat-icon
    ><span>Cart</span>
  </menu>
  <menu
    mat-menu-item
    routerLink="/account/address"
    aria-label="go to address page"
  >
    <mat-icon fontSet="material-icons-outlined">home</mat-icon
    ><span>Address</span>
  </menu>
  <menu
    mat-menu-item
    routerLink="/shopping/orders"
    aria-label="go to order page"
  >
    <mat-icon fontSet="material-icons-outlined">list_alt</mat-icon
    ><span>Order</span>
  </menu>
  <menu
    mat-menu-item
    routerLink="/account/setting"
    aria-label="go to account setting page"
  >
    <mat-icon fontSet="material-icons-outlined">settings</mat-icon
    ><span>Account</span>
  </menu>
  <mat-divider></mat-divider>
  <menu mat-menu-item (click)="logout()">
    <mat-icon fontSet="material-icons-outlined">logout</mat-icon
    ><span>Logout</span>
  </menu>
</mat-menu>

}@else {
<a mat-button aria-label="signup/login" routerLink="/login">Sign Up/Login</a>
}
