import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  constructor(private title: Title) {}

  set(name?: string, route?: ActivatedRoute) {
    var title;
    if (!name) {
      title = 'Super Apparel - China-Based Supplier';
    } else {
      title = `${name} - Super Apparel`;
    }
    this.title.setTitle(title);
    if (route?.routeConfig) {
      route.routeConfig!.data = { title };
    }
  }
}
