<div matDialogTitle>Change Password</div>

<div matDialogContent>
  <form [formGroup]="formGroup" (ngSubmit)="onUpdate()">
    <mat-form-field appearance="fill">
      <mat-label>New Password</mat-label>
      <input
        matInput
        placeholder=""
        formControlName="password"
        type="password"
      />
      <mat-icon matPrefix>lock</mat-icon>
      <mat-hint>8+ characters, 1 uppercase letter.</mat-hint>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Confirm Password</mat-label>
      <input
        matInput
        placeholder=""
        formControlName="password2"
        type="password"
      />
      <mat-icon matPrefix>lock</mat-icon>
      <mat-hint>Repeat your password</mat-hint>
      <mat-error>Passwords do not match</mat-error>
    </mat-form-field>
  </form>
</div>

<div matDialogActions align="end">
  <button mat-dialog-close mat-button>Cancel</button>
  <button mat-flat-button (click)="onUpdate()">
    @if (loading) {
    <mat-spinner [diameter]="20"></mat-spinner>
    }@else { Update }
  </button>
</div>
