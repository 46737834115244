import { NgOptimizedImage } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FileurlPipe } from '../../../pipes/fileurl.pipe';
import { OrderItemPreviewPipe } from '../../../pipes/order-item-preview.pipe';

@Component({
  selector: 'app-shopping-order-item-preview',
  standalone: true,
  imports: [NgOptimizedImage, FileurlPipe, OrderItemPreviewPipe],
  templateUrl: './shopping-order-item-preview.component.html',
  styleUrl: './shopping-order-item-preview.component.scss',
})
export class ShoppingOrderItemPreviewComponent implements OnChanges {
  @Input({ required: true }) items: any[] = [];

  groupedItems: any[] = [];
  ngOnChanges(changes: SimpleChanges): void {
    const groupedItems: any[] = [];
    for (const item of this.items) {
      var matched: boolean = false;
      for (const item0 of groupedItems) {
        if (item0.product_snapshot.no === item.product_snapshot.no) {
          matched = true;
          item0.extra = item0.extra || [];
          item0.extra.push(Object.assign({}, item));
          break;
        }
      }
      if (!matched) {
        groupedItems.push(Object.assign({ extra: [] }, item));
      }
    }
    this.groupedItems = groupedItems;
  }
}
