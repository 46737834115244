import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContactDialogComponent } from '../contact-dialog/contact-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-drawer',
  standalone: true,
  imports: [MatButtonModule, RouterModule],
  templateUrl: './drawer.component.html',
  styleUrl: './drawer.component.scss',
})
export class DrawerComponent {
  constructor(private dialog: MatDialog) {}
  openContactDialog() {
    this.dialog.open(ContactDialogComponent, {
      width: '200px',
      autoFocus: false,
    });
  }
}
