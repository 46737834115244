import {
  AfterViewInit,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { HomeProductItemComponent } from '../../product/home-product-item/home-product-item.component';
import { RouterModule } from '@angular/router';
import { ProductApiService } from '../../../services/api/product-api.service';
import { SwiperOptions } from 'swiper/types';
import {
  A11y,
  Autoplay,
  Mousewheel,
  Navigation,
  Scrollbar,
} from 'swiper/modules';
import { Platform } from '@angular/cdk/platform';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PromotionApiService } from '../../../services/api/promotion-api.service';

@Component({
  selector: 'app-home-section-category',
  standalone: true,
  imports: [
    MatDividerModule,
    HomeProductItemComponent,
    RouterModule,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './home-section-category.component.html',
  styleUrl: './home-section-category.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HomeSectionCategoryComponent implements OnChanges {
  @Input({ required: true }) data: any;

  constructor(
    private promotionApi: PromotionApiService,
    private platform: Platform
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.findProducts();
  }

  autoPlayDelay = Math.floor(Math.random() * (5000 - 3000 + 1)) + 3000;

  products: any[] = [];
  loading: boolean = false;
  async findProducts() {
    try {
      this.loading = true;
      const r = await this.promotionApi.findProductCategoryPromotionProducts(
        this.data.id
      );
      this.products = r.data || [];
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
}
