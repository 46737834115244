import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titlecase',
  standalone: true,
})
export class TitlecasePipe implements PipeTransform {
  transform(s: string): string {
    const seps = s.split(' ');
    for (let i = 0; i < seps.length; i++) {
      if (seps[i]) {
        seps[i] = seps[i][0].toUpperCase() + seps[i].slice(1);
      }
    }
    return seps.join(' ');
  }
}
