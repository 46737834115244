import { Component, OnInit } from '@angular/core';
import { TitleService } from '../../../services/title.service';
import { UserApiService } from '../../../services/api/user-api.service';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRipple } from '@angular/material/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { UpdateUserAddressDialogComponent } from '../../../components/user/update-user-address-dialog/update-user-address-dialog.component';
import { UserAddressItemComponent } from '../../../components/user/user-address-item/user-address-item.component';
import { animate, style, transition, trigger } from '@angular/animations';
import { removeItemInArray } from '../../../utils/array';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragHandle,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { ApiStatus } from '../../../services/api/status';
import { MessageService } from '../../../services/message.service';

@Component({
  selector: 'app-user-address-page',
  standalone: true,
  imports: [
    NzEmptyModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    MatRipple,
    MatDialogModule,
    UserAddressItemComponent,
    CdkDropList,
    CdkDrag,
    CdkDragHandle,
  ],
  templateUrl: './user-address-page.component.html',
  styleUrl: './user-address-page.component.scss',
  animations: [
    trigger('showup', [
      transition(
        ':enter',
        [
          style({ opacity: 0, transform: 'translateX(40px)' }),
          animate(
            '{{duration}}ms {{delay}}ms ease-in-out',
            style({ opacity: 1, transform: 'translateX(0px)' })
          ),
        ],
        { params: { duration: 500, delay: 250 } }
      ),
    ]),
  ],
})
export class UserAddressPageComponent implements OnInit {
  constructor(
    private titleService: TitleService,
    private userApi: UserApiService,
    private dialog: MatDialog,
    private message: MessageService
  ) {
    this.titleService.set('My Shipping Addresses');
  }

  loading: boolean = false;
  ngOnInit(): void {
    this.findUserAddresses();
  }

  items: any[] = [];
  async findUserAddresses() {
    try {
      this.loading = true;
      const r = await this.userApi.findUserAddresses();
      this.items = r.data || [];
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  newAddress() {
    this.dialog
      .open(UpdateUserAddressDialogComponent, {
        width: '480px',
        autoFocus: false,
        data: {},
      })
      .afterClosed()
      .subscribe((r) => {
        if (r) {
          this.items.push(r);
          // this.findUserAddresses();
        }
      });
  }

  updateAddress(data: any, i: number) {
    this.dialog
      .open(UpdateUserAddressDialogComponent, {
        width: '480px',
        autoFocus: false,
        data: Object.assign({}, data),
      })
      .afterClosed()
      .subscribe((r) => {
        if (r === false) {
          //删除
          this.items = removeItemInArray(this.items, i);
        } else if (r) {
          this.items[i] = r;
        }
      });
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    const ids: number[] = [];
    for (const item of this.items) {
      ids.push(item.id);
    }
    this.updateUserAddressSort(ids);
  }

  async updateUserAddressSort(ids: number[]) {
    try {
      const r = await this.userApi.updateUserAddressSort(ids);
      if (r.status !== ApiStatus.OK) {
        this.message.open('Update user address sort error', 'OK');
      }
    } catch (error) {}
  }
}
