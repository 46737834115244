export const environment = {
  isDevelopment: false,
  contact: {
    whatsapp: '8613958402194',
    email: 'alice@superapparels.com',
  },
  google: {
    reCaptcha: {
      siteKey: '6LfBzzcqAAAAAGDxiJXlK2bw5T0W1m8m0gpEDIMS',
    },
  },
  api: {
    host: 'https://superapparel.online/api',
  },
};
