<div class="page-content">
  @if (loading) {
  <mat-spinner [diameter]="64"></mat-spinner>
  }@else if(status===0||status===2007) {
  <mat-icon inline class="verified">verified</mat-icon>
  <h1>Thank you!</h1>
  <p>Your email verification is complete. Welcome to our Super Apparel!</p>
  @if (authService.self()) {
  <a
    mat-flat-button
    routerLink="/"
    [replaceUrl]="true"
    aria-label="go to home page"
    >Back to Home</a
  >
  }@else {
  <a
    mat-flat-button
    routerLink="/login"
    [replaceUrl]="true"
    aria-label="go to login page"
    >Login</a
  >
  } }@else if(status===2005||status===2008){
  <mat-icon inline class="unverified">info</mat-icon>
  <h1>Invalid Link</h1>
  <p>
    The verification link is invalid. if you think this is an error, please
    contact us
  </p>
  <a
    mat-flat-button
    routerLink="/"
    [replaceUrl]="true"
    aria-label="go to home page"
    >Back to Home</a
  >
  }@else if(status===2006||status===2009){
  <mat-icon inline class="unverified">info</mat-icon>
  <h1>Link Expired</h1>
  <p>This verification link has already been used or has expired.</p>
  <p>
    Please check your email for a new verification message or request a new one.
  </p>
  <a
    mat-flat-button
    routerLink="/"
    [replaceUrl]="true"
    aria-label="go to home page"
    >Back to Home</a
  >
  }
</div>
