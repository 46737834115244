<mat-drawer-container autosize>
  <mat-drawer #leftDrawer mode="over" (openedChange)="onDrawerToggled($event)">
    <app-drawer></app-drawer>
  </mat-drawer>

  <mat-drawer
    #rightDrawer
    mode="over"
    position="end"
    (openedChange)="onDrawerToggled($event)"
  >
    <app-shopping-cart-drawer
      (onClose)="rightDrawer.close()"
    ></app-shopping-cart-drawer>
  </mat-drawer>

  <mat-drawer-content>
    <app-header
      (onMenuToggle)="leftDrawer.toggle()"
      (onCartToggle)="rightDrawer.toggle()"
    ></app-header>
    <div class="flex-spacer">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </mat-drawer-content>
</mat-drawer-container>
