<a
  [routerLink]="['/shopping/product', data.no]"
  aria-label="go to product page"
>
  <mat-card matRipple>
    <mat-card-content>
      <div class="img">
        @if (data) {
        <img ngSrc="{{ data?.images[0] | fileurl }}" fill [alt]="data?.no" />
        }
      </div>
    </mat-card-content>
    <mat-card-footer>
      <div class="no">
        <h3>{{ data?.no }}</h3>
        <span>
          $ <span>{{ data | productPrice }}</span>
        </span>
      </div>
      <div class="name">
        <span>{{ data.name }}</span>
      </div>
    </mat-card-footer>
  </mat-card>
</a>
