@if (loading) {
<div class="loading">
  <mat-spinner [diameter]="60"></mat-spinner>
</div>
}@else if(data) {
<div class="page-content">
  <h1>Order Details</h1>
  <div class="summary">
    <div class="item" matRipple (click)="copyID()">
      <h5>Order ID</h5>
      <div>
        {{ data.id }}
      </div>
    </div>
    <div class="item" matRipple (click)="showStatus()">
      <h5>Status</h5>
      <div>
        <app-shopping-order-status
          [status]="data.status"
        ></app-shopping-order-status>
      </div>
    </div>
    <div class="item">
      <h5>Item Quantity</h5>
      <div>
        <span class="quantity">{{ data.quantity | number }} </span>
      </div>
    </div>
    <div class="item">
      <h5>Product Count</h5>
      <div>
        <span class="quantity">{{ groupedItems.length | number }} </span>
      </div>
    </div>
    <div class="item">
      <h5>Subtotal</h5>
      <div>
        $ <span class="price"> {{ data.price | number : ".1" }}</span>
        @if (data.original_price>data.price) {
        <span class="original_price"
          >({{ data.original_price | number : ".1" }})</span
        >
        }
      </div>
    </div>
    <div class="item">
      <h5>Shipping Cost</h5>
      <div>
        @if (data.status!=='Pending') { $
        <span class="price"> {{ data.shipping_fee | number : ".1" }}</span>
        }@else {
        <span class="tbd">TBD</span>
        }
      </div>
    </div>
    <div class="item">
      <h5>Total Amount(with Shipping)</h5>
      <div>
        @if (data.status!=='Pending') { $
        <span class="price">
          {{ data.shipping_fee + data.price | number : ".1" }}</span
        >
        }@else {
        <span class="tbd">TBD</span>

        }
      </div>
    </div>
    <div class="item">
      <h5>Amount Paid</h5>
      <div>
        @if (data.status==='Paid' || data.status==='PartiallyShipped' ||
        data.status==='Shipped' || data.status==='Completed') { $
        <span class="price"> {{ data.paid_price | number : ".1" }}</span>
        }
      </div>
    </div>
    <div class="item">
      <h5>Recipient</h5>
      <div>
        {{ data.address.fname }} {{ data.address.lname }} ({{
          data.address.phone
        }})
      </div>
    </div>
    <div class="item">
      <h5>Shipping Address</h5>
      <div>
        {{ data.address.address }}, {{ data.address.country }} ({{
          data.address.zipcode
        }})
      </div>
    </div>
    @if (data.user_remark) {
    <div class="item">
      <h5>Customer Remark</h5>
      <div>{{ data.user_remark }}</div>
    </div>
    } @if(data.shop_remark){
    <div class="item">
      <h5>Merchant Remark</h5>
      <div>{{ data.shop_remark }}</div>
    </div>
    }
    <div class="item">
      <h5>Creation Time</h5>
      <div>{{ data.created_time | date : "short" }}</div>
    </div>
  </div>

  <mat-divider></mat-divider>

  @if (data.status==='Approved') {
  <div class="payment">
    <h4>Payment Methods</h4>
    <p>
      Hello, your order has been approved and the shipping cost has been
      confirmed. Please proceed with the payment using the methods provided
      below.
    </p>

    <p>
      The total amount due for this order is $<span class="price">{{
        data.price + data.shipping_fee | number : ".1"
      }}</span
      >.
    </p>

    <app-shopping-payment-panel></app-shopping-payment-panel>
    <p>
      Once the payment is complete, kindly contact us, and we will arrange for
      shipment as quickly as possible.
    </p>
  </div>
  } @if (data.status==='Approved'||data.status==='Pending') {
  <div class="actions">
    <button mat-raised-button (click)="cancel()">Cancel</button>
  </div>
  <mat-divider></mat-divider>
  }

  <app-shopping-order-item-list
    [items]="groupedItems"
  ></app-shopping-order-item-list>
</div>
}
