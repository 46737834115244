@if (loading) {
<div class="loading">
  <mat-spinner [diameter]="60"></mat-spinner>
</div>
}@else if(data){
<div class="page-content">
  <h1>
    {{ data?.name }}
    <span>({{ total | number }})</span>
  </h1>
  <span>{{ data?.created_time | date }}</span>

  @if (data?.description) {
  <p>{{ data?.description }}</p>
  }
</div>
<div class="items">
  @for (item of items; track $index) {
  <app-product-list-item [data]="item" [index]="$index"></app-product-list-item>
  } @if (itemsLoading) {
  <div class="moreloading">
    <mat-spinner [diameter]="60"></mat-spinner>
  </div>
  }@else if(!hasMore){
  <div class="end">
    <span>You've reached the end of the list. No more items available.</span>
  </div>
  }
</div>
}@else {
<div class="not-found">
  <h1>WHOOPS</h1>
  <p>
    It looks like something has gone wrong, either the link is broken or the
    page has been removed from Super Apparel.
  </p>
  <button mat-stroked-button routerLink="/" [replaceUrl]="true">
    Back to Home
  </button>
</div>
}
