import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderItemPreview',
  standalone: true,
})
export class OrderItemPreviewPipe implements PipeTransform {
  transform(item: any, ...args: unknown[]): string {
    if (item.priced_attr_value_snapshot.image) {
      return item.priced_attr_value_snapshot.image;
    } else if (
      item.attr_values_snapshot.length > 0 &&
      item.attr_values_snapshot[0].image
    ) {
      return item.attr_values_snapshot[0].image;
    } else if (item.product_snapshot.images.length > 0) {
      return item.product_snapshot.images[0];
    }
    return '';
  }
}
