<div matDialogTitle>Update Name</div>

<div matDialogContent>
  <form [formGroup]="formGroup">
    <mat-form-field appearance="fill">
      <mat-label>First Name</mat-label>
      <input matInput placeholder="" formControlName="fname" maxlength="60" />
      <mat-error>Please input your first name.</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Last Name</mat-label>
      <input matInput placeholder="" formControlName="lname" maxlength="60" />
      <mat-error>Please input your last name.</mat-error>
    </mat-form-field>
  </form>
</div>

<div matDialogActions align="end">
  <button mat-dialog-close mat-button>Cancel</button>
  <button mat-flat-button (click)="onUpdate()">
    @if (loading) {
    <mat-spinner [diameter]="20"></mat-spinner>
    }@else { Update }
  </button>
</div>
