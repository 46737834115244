import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  constructor(private http: HttpService) {}
  buildurl(path: string, queryMap: any = null): string {
    return this.http.buildurl('/user' + path, queryMap);
  }

  signup(email: string, fname: string, lname: string, password: string) {
    const url = this.buildurl('/signup');
    const body = { email, password, fname, lname };
    return this.http.post(url, body);
  }

  login(email: string, password: string, remember?: boolean) {
    const url = this.buildurl('/login');
    const body = { email, password, remember };
    return this.http.put(url, body);
  }

  getSelf() {
    const url = this.buildurl('/self');
    return this.http.fget(url);
  }

  logout() {
    const url = this.buildurl('/logout');
    return this.http.put(url, {});
  }

  findUserAddresses() {
    const url = this.buildurl('/addresses');
    return this.http.fget(url);
  }

  createUserAddress({
    fname,
    lname,
    phone,
    address,
    country,
    zipcode,
    company,
  }: {
    fname: string;
    lname: string;
    phone: string;
    address: string;
    country: string;
    zipcode: string;
    company: string;
  }) {
    const url = this.buildurl('/address');
    const body = { fname, lname, phone, address, country, zipcode, company };
    return this.http.post(url, body);
  }

  updateUserAddress(
    id: number,
    {
      fname,
      lname,
      phone,
      address,
      country,
      zipcode,
      company,
    }: {
      fname: string;
      lname: string;
      phone: string;
      address: string;
      country: string;
      zipcode: string;
      company: string;
    }
  ) {
    const url = this.buildurl(`/address/${id}`);
    const body = { fname, lname, phone, address, country, zipcode, company };
    return this.http.put(url, body);
  }

  deleteUserAddress(id: number) {
    const url = this.buildurl(`/address/${id}`);
    return this.http.delete(url);
  }

  updateUserAddressSort(ids: number[]) {
    const url = this.buildurl('/address/sort');
    const body = { ids };
    return this.http.put(url, body);
  }

  updateAccountEmail({ email }: { email: string }) {
    const url = this.buildurl('/account/email');
    const body = { email };
    return this.http.put(url, body);
  }

  verifyUserEmail({ id, token }: { id: string; token: string }) {
    const url = this.buildurl('/account/email-verification');
    const body = { id, token };
    return this.http.post(url, body);
  }

  sendResetPasswordEmail({ email, token }: { email: string; token: string }) {
    const url = this.buildurl('/account/password-reset-email');
    const body = { email, token };
    return this.http.post(url, body);
  }

  resetPassword({
    password,
    id,
    token,
  }: {
    id: string;
    password: string;
    token: string;
  }) {
    const url = this.buildurl('/account/password-reset');
    const body = { password, token, id };
    return this.http.put(url, body);
  }

  updateAccountName({ fname, lname }: { fname: string; lname: string }) {
    const url = this.buildurl('/account/name');
    const body = { fname, lname };
    return this.http.put(url, body);
  }

  updateAccountPassword({ password }: { password: string }) {
    const url = this.buildurl('/account/password');
    const body = { password };
    return this.http.put(url, body);
  }
}
