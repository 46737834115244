@if (!noTitle) {
<div class="no">
    <h5>{{ data.product_snapshot.no }}</h5>
</div>
<div class="name">
    <img ngSrc="{{ data.product_snapshot.images[0] | fileurl }}" width="64" height="64" />
    <h4>{{ data.product_snapshot.name }}</h4>
</div>
}
<div class="divider"></div>
<a class="attr-values" [routerLink]="['/shopping/product',data.product_snapshot.no]" [queryParams]="{attrs:queryAttrs}" target="_blank"
    aria-label="go to product page" [matTooltip]="data.shipped?'Shipped':'Unshipped'" [ngClass]="{shipped:data.shipped}">
    @for (value of data.attr_values_snapshot; track $index) {
    <div class="attr-value">
        <span class="name">{{ value.attr.name }}:</span>
        @if (value.image) {
        <img ngSrc="{{ value.image | fileurl }}" width="36" height="36" />
        }
        <span class="value">{{ value.name |titlecase}}</span>
    </div>
    <div class="divider"></div>
    }
    <div class="attr-value">
        <span class="name">{{ data.priced_attr_value_snapshot.attr.name }}:</span>
        @if (data.priced_attr_value_snapshot.image) {
        <img ngSrc="{{ data.priced_attr_value_snapshot.image | fileurl }}" width="36" height="36" />
        }
        <span class="value">{{ data.priced_attr_value_snapshot.name |titlecase}}</span>
        <span class="price">
            $ <span class="amount">{{ data.price || data.priced_attr_value_snapshot.price|number:'.1' }} </span>
            @if (data.price>0&&data.price<data.priced_attr_value_snapshot.price) { <span class="origin">(${{
                data.priced_attr_value_snapshot.price|number:'.1' }})</span>
        }
        </span>
    </div>
</a>
<!-- <div class="divider"></div> -->
<div class="summary">
    Quantity: {{data.quantity|number}}
    <div class="flex-spacer"></div>
    <span class="price">
        $ <span class="amount">{{ data.price * data.quantity |number:'.1'}} </span>
    </span>
</div>

<ng-content></ng-content>

