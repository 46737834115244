import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { CartService } from '../../../services/cart.service';
@Component({
  selector: 'app-user-shopping-bag-button',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, MatBadgeModule],
  templateUrl: './user-shopping-bag-button.component.html',
  styleUrl: './user-shopping-bag-button.component.scss',
})
export class UserShoppingBagButtonComponent {
  constructor(public cartService: CartService) {}
}
