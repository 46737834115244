import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { TitleService } from '../../../services/title.service';

@Component({
  selector: 'app-terms-of-use-page',
  standalone: true,
  imports: [],
  templateUrl: './terms-of-use-page.component.html',
  styleUrl: './terms-of-use-page.component.scss',
})
export class TermsOfUsePageComponent {
  constructor(private title: TitleService) {
    this.title.set('Terms of Use');
  }
}
