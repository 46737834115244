import { Component, effect, EventEmitter, Output } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { UserApiService } from '../../../services/api/user-api.service';
import { UserAddressItemComponent } from '../user-address-item/user-address-item.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { InfoDialogComponent } from '../../common/info-dialog/info-dialog.component';
import { Router } from '@angular/router';
import { UserAddressSelectDialogComponent } from '../user-address-select-dialog/user-address-select-dialog.component';

@Component({
  selector: 'app-user-address-select',
  standalone: true,
  imports: [UserAddressItemComponent, MatDialogModule],
  templateUrl: './user-address-select.component.html',
  styleUrl: './user-address-select.component.scss',
})
export class UserAddressSelectComponent {
  @Output() onAddrSelected = new EventEmitter<any>();
  constructor(
    private authService: AuthService,
    private userApi: UserApiService,
    private dialog: MatDialog,
    private router: Router
  ) {
    effect(() => {
      const self = this.authService.self();
      if (self) {
        this.findUserAddresses();
      }
    });
  }

  addr: any;
  updateAddr(r: any) {
    this.addr = r;
    this.onAddrSelected.emit(r);
  }
  async findUserAddresses() {
    try {
      const r = await this.userApi.findUserAddresses();
      if (r.data.length > 0) {
        this.updateAddr(r.data[0]);
      }
    } catch (error) {}
  }

  select() {
    if (!this.authService.self()) {
      this.dialog.open(InfoDialogComponent, {
        data: {
          title: 'Login Required',
          messages: [
            'Please login first before you ',
            'select a shipping address and place your order.',
          ],
          actions: [
            {
              text: 'Login',
              onClick: () =>
                this.router.navigate(['/login'], {
                  queryParams: { r: this.router.url },
                }),
            },
          ],
        },
        autoFocus: false,
      });
      return;
    }

    this.dialog
      .open(UserAddressSelectDialogComponent, {
        width: '800px',
        autoFocus: false,
      })
      .afterClosed()
      .subscribe((r: any) => {
        if (r) {
          this.updateAddr(r);
        }
      });
  }
}
