import { Injectable, signal } from '@angular/core';
import { UserApiService } from './api/user-api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  self = signal<any>(null);
  constructor(private userApi: UserApiService) {}

  async getSelf() {
    try {
      const r = await this.userApi.getSelf();
      this.self.set(r.data);
    } catch (error) {}
  }

  setSelf(r: any) {
    this.self.set(r);
  }

  async logout() {
    await this.userApi.logout();
    this.self.set(null);
  }
}
