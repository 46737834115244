import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class ProductApiService {
  constructor(private http: HttpService) {}
  buildurl(path: string, queryMap: any = null): string {
    return this.http.buildurl('/product' + path, queryMap);
  }

  findProducts({
    query,
    categoryID,
    page = 1,
    pageSize = 20,
  }: {
    query?: string;
    categoryID?: number;
    page: number;
    pageSize: number;
  }) {
    const url = this.buildurl(`/products`, {
      query,
      category_id: categoryID,
      page,
      page_size: pageSize,
    });
    return this.http.fget(url);
  }

  findProductCategories() {
    const url = this.buildurl(`/categories`);
    return this.http.fget(url);
  }

  getProductByNO(no: string) {
    const url = this.buildurl(`/product/${no}`);
    return this.http.fget(url);
  }

  getProductList(id: string) {
    const url = this.buildurl(`/list/${id}`);
    return this.http.fget(url);
  }

  findProductListItems(
    id: string,
    { page, pageSize }: { page: number; pageSize: number }
  ) {
    const url = this.buildurl(`/list/${id}/items`, {
      page,
      page_size: pageSize,
    });
    return this.http.fget(url);
  }
}
