<div class="header">
  <!-- <img ngSrc="images/logo.png" width="168" height="168" alt="alice yang" /> -->
  <h1 [@showup]="animationParams1">Super Apparel</h1>
  <h3 [@showup]="animationParams2">Yiwu Xuanyu Apparel Co., Ltd.</h3>
  <p [@showup]="animationParams3">
    is a leading manufacturer and exporter specializing in women's light woven
    wear.
  </p>
  <p [@showup]="animationParams3">
    We excel in design, development, and production, providing high-quality
    apparel to retailers, designer brands, and wholesalers.
  </p>
  <p [@showup]="animationParams3">
    Our commitment to excellence and long-term business partnerships drives our
    success in the global market.
  </p>
  <p [@showup]="animationParams4">
    If you have any questions, please be free to ask.
  </p>
  <p [@showup]="animationParams4">We'll try best to meet your needs.</p>
  <div class="content" [@showup]="animationParams5">
    <app-contact-whatsapp-button></app-contact-whatsapp-button>
    <app-contact-email-button></app-contact-email-button>
  </div>
</div>
