import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-user-address-item',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './user-address-item.component.html',
  styleUrl: './user-address-item.component.scss',
})
export class UserAddressItemComponent {
  @Input({ required: true }) data: any;
}
