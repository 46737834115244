<div class="page-content">
  <h1>Frequently Asked Questions</h1>
  <mat-accordion multi>
    @for (faq of faqs; track $index) {
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>{{ faq.question }}</mat-panel-title>
      </mat-expansion-panel-header>
      <p>
        {{ faq.answer }}
      </p>
    </mat-expansion-panel>
    }
  </mat-accordion>
</div>
