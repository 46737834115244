import { NgOptimizedImage } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FileurlPipe } from '../../../pipes/fileurl.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { TitlecasePipe } from '../../../pipes/titlecase.pipe';
import { MatButtonModule } from '@angular/material/button';
import { NzImageModule, NzImageService } from 'ng-zorro-antd/image';
import { FileApiService } from '../../../services/api/file-api.service';

@Component({
  selector: 'app-product-attr-value-item',
  standalone: true,
  imports: [
    NgOptimizedImage,
    FileurlPipe,
    MatIconModule,
    TitlecasePipe,
    MatButtonModule,
    NzImageModule,
  ],
  templateUrl: './product-attr-value-item.component.html',
  styleUrl: './product-attr-value-item.component.scss',
})
export class ProductAttrValueItemComponent {
  @Input({ required: true }) data: any;

  constructor(
    private imageService: NzImageService,
    private fileApi: FileApiService
  ) {}

  viewImage(e: MouseEvent) {
    // e.preventDefault();
    // e.stopPropagation();
    if (this.data.image) {
      this.imageService.preview([
        {
          src: this.fileApi.fileurl(this.data.image),
        },
      ]);
    }
  }
}
