import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ShoppingApiService } from '../../../services/api/shopping-api.service';
import { CartService } from '../../../services/cart.service';
import { Platform } from '@angular/cdk/platform';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ShoppingCartItemComponent } from '../../shopping/shopping-cart-item/shopping-cart-item.component';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DecimalPipe } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-shopping-cart-drawer',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    ShoppingCartItemComponent,
    NzEmptyModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    DecimalPipe,
    MatDividerModule,
    RouterModule,
  ],
  templateUrl: './shopping-cart-drawer.component.html',
  styleUrl: './shopping-cart-drawer.component.scss',
})
export class ShoppingCartDrawerComponent {
  @Output() onClose = new EventEmitter<boolean>();
  constructor(public cartService: CartService, private platform: Platform) {}

  ngOnInit(): void {
    if (environment.isDevelopment && !this.platform.isBrowser) {
      return;
    }

    this.cartService.findShoppingCartItems();
  }

  loading: boolean = false;
  async onItemRemoved(data: any) {
    try {
      this.loading = true;
      await this.cartService.deleteUserShoppingCartItem(data.id);
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
}
