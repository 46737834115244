<mat-accordion multi>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-icon>account_balance</mat-icon><span>Bank Transfer</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-list>
      <mat-list-item>
        <div matListItemTitle>Account Number</div>
        <div matListItemLine>8337203288</div>
      </mat-list-item>
      <mat-list-item>
        <div matListItemTitle>ABA/ Routing Number</div>
        <div matListItemLine>026073008</div>
      </mat-list-item>
      <mat-list-item>
        <div matListItemTitle>Account Name</div>
        <div matListItemLine>Yiwu Xuanyu Clothing Co., Ltd</div>
        <div matListItemLine>
          <span
            >* If the account name is too long, you can continue filling it into
            the address field.</span
          >
        </div>
      </mat-list-item>
      <mat-list-item>
        <div matListItemTitle>Bank Name</div>
        <div matListItemLine>Community Federal Savings Bank</div>
      </mat-list-item>
      <mat-list-item>
        <div matListItemTitle>Bank Address</div>
        <div matListItemLine>Seventh Avenue, New York, NY 10019, US</div>
      </mat-list-item>
      <mat-list-item>
        <div matListItemTitle>Type of Account</div>
        <div matListItemLine>Business Account</div>
      </mat-list-item>
      <mat-list-item>
        <div matListItemTitle>Remark</div>
        <div matListItemLine>
          <span
            >For the payment of goods, please make a Fedwire/Wire Payment</span
          >
        </div>
      </mat-list-item>
    </mat-list>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <img ngSrc="images/third/paypal.svg" width="24" height="24" />
        <span>PayPal</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-list>
      <mat-list-item>
        <div matListItemTitle>Account</div>
        <div matListItemLine>superapparel&#64;126.com</div>
      </mat-list-item>
      <mat-list-item>
        <div matListItemTitle>Remark</div>
        <div matListItemLine>
          <span>Please pay by related, or we will charge 4% Transfer.</span>
        </div>
      </mat-list-item>
    </mat-list>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <img ngSrc="images/third/alibaba.svg" width="24" height="24" />
        <span>Alibaba</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-list>
      <mat-list-item>
        <div matListItemTitle>Account</div>
        <div matListItemLine>
          <span
            >Please contact us to receive the alibaba payment options.
          </span>
        </div>
      </mat-list-item>
    </mat-list>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-icon>credit_card</mat-icon><span>Credit Card</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-list>
      <mat-list-item>
        <div matListItemTitle>Account</div>
        <div matListItemLine>
          <span
            >Please contact us to receive the credit card payment options.
          </span>
        </div>
      </mat-list-item>
    </mat-list>
  </mat-expansion-panel>
</mat-accordion>
