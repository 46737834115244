<div matDialogTitle>Confirm Order</div>
<div matDialogContent class="content">
  <p>
    Thank you for your trust. Before proceeding, please confirm the following
    information.
  </p>
  <mat-divider></mat-divider>
  <div class="list">
    <div class="item">
      <span class="title" matTooltip="Total number of different product types."
        >Product Count</span
      >
      <span class="value">{{ data.totalProductCount | number }}</span>
    </div>
    <div class="item">
      <span class="title" matTooltip="Total number of items in your order."
        >Item Quantity</span
      >
      <span class="value">{{ data.totalItemQuantity | number }}</span>
    </div>
    <div class="item">
      <span class="title">Subtotal</span>
      <span class="value">$ {{ data.totalPrice | number : ".1" }}</span>
    </div>
    <div class="item">
      <span class="title">Recipient</span>
      <span class="value">
        {{ data.addr.fname }} {{ data.addr.lname }} ({{ data.addr.phone }})
      </span>
    </div>
    <div class="item">
      <span class="title">Shipping Address</span>
      <span class="value">
        {{ data.addr.address }}, {{ data.addr.country }} ({{
          data.addr.zipcode
        }})
      </span>
    </div>
    @if (data.remark) {
    <div class="item">
      <span class="title">Remark</span>
      <span class="value">{{ data.remark }}</span>
    </div>
    }
  </div>
  <mat-divider></mat-divider>
  <ul>
    <li>
      We will review your order as soon as possible and calculate the shipping
      cost based on your delivery address.
    </li>
    <li>
      <b>In-Stock Items</b>: Orders will be shipped within 3 days if stock is
      available.
    </li>
    <li>
      <b>Out-of-Stock Items</b>: For items with insufficient stock, we will
      restock and ship within 18 days.
    </li>
  </ul>
</div>
<div matDialogActions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-flat-button (click)="close(true)">Confirm</button>
</div>
