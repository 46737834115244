import { Component, OnInit } from '@angular/core';
import { TitleService } from '../../../services/title.service';
import { CartService } from '../../../services/cart.service';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { DecimalPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NzEmptyModule } from 'ng-zorro-antd/empty';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ShoppingCartSummaryPanelComponent } from '../../../components/shopping/shopping-cart-summary-panel/shopping-cart-summary-panel.component';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../../environments/environment';
import { ShoppingCartItemComponent } from '../../../components/shopping/shopping-cart-item/shopping-cart-item.component';
import { animate, style, transition, trigger } from '@angular/animations';
@Component({
  selector: 'app-shopping-cart-page',
  standalone: true,
  imports: [
    ShoppingCartItemComponent,
    MatListModule,
    MatIconModule,
    DecimalPipe,
    MatButtonModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    NzEmptyModule,
    MatInputModule,
    MatFormFieldModule,
    ShoppingCartSummaryPanelComponent,
  ],
  templateUrl: './shopping-cart-page.component.html',
  styleUrl: './shopping-cart-page.component.scss',
  animations: [
    trigger('showup', [
      transition(
        ':enter',
        [
          style({ opacity: 0, transform: 'translateY(20px)' }),
          animate(
            '{{duration}}ms {{delay}}ms ease-in-out',
            style({ opacity: 1, transform: 'translateY(0px)' })
          ),
        ],
        { params: { duration: 500, delay: 250 } }
      ),
    ]),
  ],
})
export class ShoppingCartPageComponent {
  constructor(
    private titleService: TitleService,
    public cartService: CartService,
    private platform: Platform
  ) {
    this.titleService.set('Shopping Cart');
  }

  loading: boolean = false;

  ngOnInit(): void {
    if (environment.isDevelopment && !this.platform.isBrowser) {
      return;
    }
    this.cartService.findShoppingCartItems();
  }

  async onItemRemoved(data: any) {
    try {
      this.loading = true;
      await this.cartService.deleteUserShoppingCartItem(data.id);
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }
}
