import { Component, effect } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { UserApiService } from '../../../services/api/user-api.service';
import { MatButtonModule } from '@angular/material/button';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { validateFormGroup, validateNonEmptyString } from '../../../utils/form';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AuthService } from '../../../services/auth.service';
import { ApiStatus } from '../../../services/api/status';
import { MessageService } from '../../../services/message.service';

@Component({
  selector: 'app-update-account-name-dialog',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './update-account-name-dialog.component.html',
  styleUrl: './update-account-name-dialog.component.scss',
})
export class UpdateAccountNameDialogComponent {
  formGroup: FormGroup;
  constructor(
    private dialogRef: MatDialogRef<UpdateAccountNameDialogComponent>,
    private userApi: UserApiService,
    private fb: FormBuilder,
    private authService: AuthService,
    private messageService: MessageService
  ) {
    this.formGroup = this.fb.group({
      fname: ['', [Validators.required, validateNonEmptyString]],
      lname: ['', [Validators.required, validateNonEmptyString]],
    });
    effect(() => {
      const user = this.authService.self();
      if (user) {
        this.formGroup.patchValue({
          fname: user.fname,
          lname: user.lname,
        });
      }
    });
  }

  close(r: any = null) {
    this.dialogRef.close(r);
  }

  loading: boolean = false;
  onUpdate() {
    if (this.loading) {
      return;
    }
    const values = validateFormGroup(this.formGroup);
    if (!values) {
      return;
    }
    this.updateAccountName(values.fname, values.lname);
  }

  async updateAccountName(fname: string, lname: string) {
    try {
      this.loading = true;
      const r = await this.userApi.updateAccountName({ fname, lname });
      if (r.status !== ApiStatus.OK) {
        this.messageService.open('Unknown Error', 'OK');
      } else {
        this.authService.setSelf(r.data);
        this.close(r.data);
      }
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }
}
