<div mat-dialog-title>@if (data?.id) { Edit Address }@else{ New Address }</div>

<div matDialogContent>
  <form [formGroup]="formGroup">
    <div class="row">
      <mat-form-field appearance="fill">
        <mat-label>First Name</mat-label>
        <input matInput placeholder="" formControlName="fname" />
        <mat-error>Please input your first name.</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Last Name</mat-label>
        <input matInput placeholder="" formControlName="lname" />
        <mat-error>Please input your last name.</mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="fill">
        <mat-label>Country</mat-label>
        <input
          matInput
          placeholder=""
          formControlName="country"
          [matAutocomplete]="auto"
        />
        <mat-error>Please input your country.</mat-error>
      </mat-form-field>
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        @for (option of countryFilteredOptions | async; track option) {
        <mat-option [value]="option">{{ option }}</mat-option>
        }
      </mat-autocomplete>
      <mat-form-field appearance="fill">
        <mat-label>Zip Code</mat-label>
        <input matInput placeholder="" formControlName="zipcode" />
        <mat-error>Please input your zipcode.</mat-error>
      </mat-form-field>
    </div>
    <mat-form-field appearance="fill">
      <mat-label>Address</mat-label>
      <input matInput placeholder="" formControlName="address" />
      <mat-icon matPrefix>home_pin</mat-icon>

      <mat-error>Please input your address.</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Phone</mat-label>
      <input matInput placeholder="" formControlName="phone" />
      <mat-icon matPrefix>call</mat-icon>
      <mat-error>Please input your phone number.</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Company</mat-label>
      <input matInput placeholder="" formControlName="company" />
      <mat-icon matPrefix>business</mat-icon>
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions align="end">
  @if (data?.id) {
  <button mat-button (click)="deleteUserAddress()">Delete</button>
  }
  <div class="flex-spacer"></div>
  <button mat-button mat-dialog-close>Cancel</button>
  <button
    mat-flat-button
    (click)="onOk()"
    style="transition: all ease-in-out 0.25s"
  >
    @if (loading) {
    <mat-spinner [diameter]="20"></mat-spinner>
    }@else if(data?.id) { Update }@else { Create }
  </button>
</div>
