import { DecimalPipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { UserAddressItemComponent } from '../../user/user-address-item/user-address-item.component';
import { MatTooltipModule } from '@angular/material/tooltip';

export interface ConfirmOrderDialogData {
  totalItemQuantity: number;
  totalProductCount: number;
  totalPrice: number;
  addr: any;
  remark: string;
}

@Component({
  selector: 'app-confirm-order-dialog',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatListModule,
    DecimalPipe,
    MatDividerModule,
    UserAddressItemComponent,
    MatTooltipModule,
  ],
  templateUrl: './confirm-order-dialog.component.html',
  styleUrl: './confirm-order-dialog.component.scss',
})
export class ConfirmOrderDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<ConfirmOrderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmOrderDialogData
  ) {}

  close(r: any = null) {
    this.dialogRef.close(r);
  }
}
