import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { FileurlPipe } from '../../../pipes/fileurl.pipe';
import { NgOptimizedImage } from '@angular/common';
import { ProductPricePipe } from '../../../pipes/product-price.pipe';

@Component({
  selector: 'app-product-item',
  standalone: true,
  imports: [
    NgOptimizedImage,
    FileurlPipe,
    MatCardModule,
    MatRippleModule,
    RouterModule,
    ProductPricePipe,
  ],
  templateUrl: './product-item.component.html',
  styleUrl: './product-item.component.scss',
})
export class ProductItemComponent {
  @Input({ required: true }) data: any;
}
