import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private snackbar: MatSnackBar, private platform: Platform) {}

  open(message: string, action?: string) {
    if (!this.platform.isBrowser) {
      return;
    }
    this.snackbar.dismiss();
    return this.snackbar.open(message, action, {
      verticalPosition: 'top',
      duration: 5000,
    });
  }
}
