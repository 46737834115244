import { Component, EventEmitter, Output } from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-search-input',
  standalone: true,
  imports: [MatIconModule, FormsModule],
  templateUrl: './search-input.component.html',
  styleUrl: './search-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SearchInputComponent,
    },
  ],
})
export class SearchInputComponent implements ControlValueAccessor {
  value: string = '';
  @Output() search = new EventEmitter<string>();

  onValueChanged() {
    this.onFormChange(this.value);
  }
  writeValue(v: string) {
    this.value = v;
  }

  onFormChange = (v: any) => {};

  onFormTouched = () => {};

  registerOnChange(onChange: any) {
    this.onFormChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onFormTouched = onTouched;
  }

  disabled: boolean = false;
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
