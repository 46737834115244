<div matDialogTitle>
  <span> Select an Address </span>
  <button mat-button mat-dialog-close routerLink="/account/address">
    Manage
  </button>
</div>

@if (loading) {
<div class="loading">
  <mat-spinner [diameter]="48"></mat-spinner>
</div>
}@else if(items.length>0) {
<div class="items">
  @for (item of items; track $index) {
  <app-user-address-item
    [data]="item"
    (click)="selected = item"
    [ngClass]="{ selected: selected === item }"
  >
  </app-user-address-item>
  }
</div>
}@else {
<div class="empty">
  <nz-empty nzNotFoundContent=""></nz-empty>
  <p>You haven't created any addresses yet.</p>
  <p>
    Please
    <a
      mat-dialog-close
      routerLink="/account/address"
      aria-label="go to address page"
      >create</a
    >
    one first.
  </p>
</div>
}

<div matDialogActions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-flat-button [disabled]="!selected" (click)="select()">
    Select
  </button>
</div>
