import { DecimalPipe, NgClass, NgOptimizedImage } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { FileurlPipe } from '../../../pipes/fileurl.pipe';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TitlecasePipe } from '../../../pipes/titlecase.pipe';

@Component({
  selector: 'app-shopping-order-item-item',
  standalone: true,
  imports: [
    NgOptimizedImage,
    FileurlPipe,
    RouterModule,
    NgOptimizedImage,
    MatIconModule,
    DecimalPipe,
    MatTooltipModule,
    NgClass,
    TitlecasePipe,
  ],
  templateUrl: './shopping-order-item-item.component.html',
  styleUrl: './shopping-order-item-item.component.scss',
})
export class ShoppingOrderItemItemComponent {
  @HostBinding('class.no-title')
  @Input()
  noTitle: boolean = false;
  @Input({ required: true }) data: any;

  get queryAttrs(): number[] {
    const attrs = this.data.attr_value_ids.slice();
    attrs.push(this.data.priced_attr_value_id);
    return attrs;
  }
}
