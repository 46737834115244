<div class="close">
  <button
    mat-icon-button
    aria-label="close cart drawer"
    (click)="onClose.emit(true)"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
@if (cartService.groupedItems().length>0) {
<div class="items">
  @for (item of cartService.groupedItems(); track $index) {
  <div class="item">
    <app-shopping-cart-item [data]="item" (onRemove)="onItemRemoved($event)">
      @for (extra of item.extra; track $index) {
      <app-shopping-cart-item
        [data]="extra"
        (onRemove)="onItemRemoved($event)"
        [noTitle]="true"
      ></app-shopping-cart-item>
      }
    </app-shopping-cart-item>
  </div>
  }
</div>
<mat-divider></mat-divider>
<div class="action">
  <div class="info flex-spacer">
    <span>
      Subtotal: $
      <span class="price">{{ cartService.price() | number : ".1" }}</span>
    </span>
    <span>
      Quantity:
      <span class="quantity">{{ cartService.quantity() | number }}</span>
    </span>
  </div>
  <button mat-flat-button routerLink="/shopping/cart">Continue</button>
</div>
}@else {
<div class="empty">
  <nz-empty nzNotFoundContent="Your cart is empty."></nz-empty>
</div>
} @if (loading) {
<div class="loading">
  <mat-spinner [diameter]="48"></mat-spinner>
</div>
}
