export enum ApiStatus {
  OK = 0,
  ErrProductNotFound = 1001,
  ErrProductAttrInvalid = 1002,

  ErrRecaptchaTokenFailed = 2000,
  ErrUserEmailDuplicated = 2001, // 邮箱重复，已经注册
  ErrUserPasswordIncorrect = 2002, // 密码错误
  ErrUserEmailNotFound = 2003, // 邮箱未注册
  ErrUserStatusBanned = 2004, // 账号被封禁
  ErrUserEmailTokenNotFound = 2005, // 邮箱验证不存在
  ErrUserEmailTokenUsed = 2006, // 验证链接已经被使用
  ErrUserEmailUsed = 2007,
  ErrUserEmailNotMatched = 2008,
  ErrUserEmailTokenExpired = 2009,

  ErrUserOrderNotFound = 3001,
  ErrUserOrderStatusInvalid = 3002,
}

export enum UserOrderStatus {
  UserOrderStatusPending = 'Pending',
  UserOrderStatusApproved = 'Approved',
  UserOrderStatusRejected = 'Rejected',
  UserOrderStatusPaid = 'Paid',
  UserOrderStatusCancelled = 'Cancelled',
  UserOrderStatusPartiallyShipped = 'PartiallyShipped',
  UserOrderStatusShipped = 'Shipped',
  UserOrderStatusCompleted = 'Completed',
}
