import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy,
} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CustomReuseStrategy implements RouteReuseStrategy {
  public static storedRoutes: {
    [key: string]: { handle: DetachedRouteHandle; timestamp: number };
  } = {};
  private readonly EXPIRATION_TIME = 10 * 60 * 1000; // 10 minutes in milliseconds
  private readonly MAX_CACHE_SIZE = 12; // Maximum number of cached routes

  constructor(private titleService: Title) {}

  reusedRoutes: string[] = [
    '',
    'products',
    'order/:id',
    'orders',
    'product/list/:id',
  ];

  static clear() {
    CustomReuseStrategy.storedRoutes = {};
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    // Decide whether to store the route or not
    if (this.reusedRoutes.includes(route.routeConfig?.path as string)) {
      return true;
    }

    return false;
  }

  store(
    route: ActivatedRouteSnapshot,
    handle: DetachedRouteHandle | null
  ): void {
    if (route.routeConfig) {
      const routeKey = route.routeConfig.path as string;

      // Remove oldest route if cache size exceeds the limit
      if (
        Object.keys(CustomReuseStrategy.storedRoutes).length >=
        this.MAX_CACHE_SIZE
      ) {
        const oldestRouteKey = Object.keys(
          CustomReuseStrategy.storedRoutes
        ).reduce((oldest, key) => {
          return CustomReuseStrategy.storedRoutes[key].timestamp <
            CustomReuseStrategy.storedRoutes[oldest].timestamp
            ? key
            : oldest;
        });
        delete CustomReuseStrategy.storedRoutes[oldestRouteKey];
      }

      // Store the new route
      CustomReuseStrategy.storedRoutes[routeKey] = {
        handle: handle!,
        timestamp: Date.now(), // Store the current timestamp
      };
    }
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const routeKey = route.routeConfig?.path as string;
    const stored = CustomReuseStrategy.storedRoutes[routeKey];

    // Check if the route is expired or not
    if (stored && Date.now() - stored.timestamp < this.EXPIRATION_TIME) {
      return true;
    } else {
      delete CustomReuseStrategy.storedRoutes[routeKey]; // Remove expired route
      return false;
    }
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    if (!route.routeConfig) {
      return null;
    }
    const stored =
      CustomReuseStrategy.storedRoutes[route.routeConfig.path as string];
    if (!stored) {
      return null;
    }
    if (route.routeConfig?.data) {
      this.titleService.setTitle(route.routeConfig?.data['title'] || '');
    }
    return stored.handle;
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    return future.routeConfig === curr.routeConfig; // Determine if we should reuse the route
  }
}
